import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";
import { SERVER_URL } from "../globalVariables";
import { ethers } from "ethers";

const ExchangeRateContext = createContext(null);

export function ExchangeRateProvider({ children }) {
  const cachedExchangeRate = localStorage.getItem("exchangeRate");

  const [exchangeRate, setExchangeRate] = useState(
    cachedExchangeRate ? JSON.parse(cachedExchangeRate) : null
  );

  const priceConversion = async (maticPrice) => {
    if (!maticPrice) {
      return false;
    }

    try {
      let data = await fetch(
        `${SERVER_URL}/api/priceConversion?amountMatic=${maticPrice}`
      );
      data = await data.json();

      if (data) {
        const rate = data.price;

        return rate?.toFixed(4);
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    const fetchAndCacheExchangeRate = async () => {
      const now = new Date();
      const lastUpdated = exchangeRate
        ? new Date(exchangeRate.lastUpdated)
        : new Date(0);
      const minutesSinceLastUpdate = (now - lastUpdated) / (1000 * 60);

      if (exchangeRate && minutesSinceLastUpdate < 10) {
        return;
      }

      const rate = await priceConversion("1.0");

      if (rate) {
        const data = { rate: rate, lastUpdated: now.toISOString() };

        localStorage.setItem("exchangeRate", JSON.stringify(data));
        setExchangeRate(data);
      }
    };

    fetchAndCacheExchangeRate();

    const interval = setInterval(fetchAndCacheExchangeRate, 60000);
    return () => clearInterval(interval);
  }, []);

  const convertWeiToMatic = (amount) => {
    if (amount) {
      const amountInMaic = ethers.utils.formatEther(amount);
      return amountInMaic;
    } else {
      return false;
    }
  };

  const convertMaticToWei = (amount) => {
    if (amount) {
      const amountInWei = ethers.utils.parseUnits(amount.toString(), "ether");
      return amountInWei;
    } else {
      return false;
    }
  };

  const PriceInEuro = (maticPrice) => {
    const priceInEuro = maticPrice * Number(exchangeRate?.rate);

    return priceInEuro;
  };

  const VATPrice = (price, artworkSize) => {
    const VATPercentage = Number(artworkSize) <= 30 ? 5.5 : 20;
    const VATPrice = Number(price) * (VATPercentage / 100);

    return VATPrice;
  };

  const totalPriceWithVAT = (amount, artworkSize, currency) => {
    const matic = convertWeiToMatic(amount);
    const VAT = VATPrice(matic, artworkSize);
    const totalPriceInMatic = Number(matic) + Number(VAT);

    if (currency === "EUR") {
      const totalPriceInEuro = PriceInEuro(totalPriceInMatic);

      return totalPriceInEuro?.toFixed(2);
    } else {
      return totalPriceInMatic;
    }
  };

  const memodValue = useMemo(
    () => ({
      exchangeRate,
      PriceInEuro,
      VATPrice,
      totalPriceWithVAT,
      convertWeiToMatic,
      convertMaticToWei,
    }),
    [exchangeRate]
  );

  return (
    <ExchangeRateContext.Provider value={memodValue}>
      {children}
    </ExchangeRateContext.Provider>
  );
}

export function useExchangeRate() {
  return useContext(ExchangeRateContext);
}
