import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import StickBar from "../components/StickBar";
import Modal from "@mui/material/Modal";
import { TfiArrowsCorner } from "react-icons/tfi";
import CircularProgress from "@mui/material/CircularProgress";
import ArtworksGallery from "../components/ArtworksGallery";
import { RxCross2 } from "react-icons/rx";
import useWindowsResize from "../hooks/useWindowsResize";
import { SERVER_URL } from "../globalVariables";
import { useExchangeRate } from "../hooks/usePriceConversion";
import PurchaseModals from "../components/PurchaseModals";
import BuyButton from "../components/BuyButton";

const ArtistArtwork = () => {
  const { artistId, artworkName } = useParams();
  const navigate = useNavigate();
  const [openFullImageModal, setOpenFullImageModal] = useState(false);
  const [artworksIsLoading, setArtworksIsLoading] = useState(true);
  const [artwork, setArtwork] = useState(null);
  const [artworks, setArtworks] = useState(null);
  const screenSize = useWindowsResize();
  const { totalPriceWithVAT } = useExchangeRate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [artworkName, artistId]);

  useEffect(() => {
    const getArtwork = async () => {
      setArtworksIsLoading(true);

      const encodedArtworkName = encodeURIComponent(artworkName);

      fetch(
        `${SERVER_URL}/api/getArtwork?artistId=${artistId}&artworkName=${encodedArtworkName}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setArtwork(data);
          } else {
            navigate(`/`);
          }
        })
        .catch((error) => {
          console.log(error);
          navigate("/");
        });
    };

    getArtwork();
  }, [artworkName, artistId]);

  useEffect(() => {
    const getArtworks = () => {
      const encodedCollectionName = encodeURIComponent(artwork?.collectionName);

      fetch(
        `${SERVER_URL}/api/getArtworksByCollection?artistId=${artistId}&collectionName=${encodedCollectionName}`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            let otherAtworks = data.filter(
              (oeuvre) => oeuvre.name !== artworkName
            );

            setArtworks(otherAtworks);
          }
          setArtworksIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          navigate("/");
        });
    };

    if (artwork) {
      getArtworks();
    }
  }, [artwork]);

  return (
    <div className="lg:max-w-[1440px] lg:mx-auto">
      <Header moreStyle="sticky bg-[#00263a]" logo={true} />

      <div className="flex">
        <StickBar />

        {artworksIsLoading ? (
          <div className="flex w-full h-[calc(100vh-70px)] lg:h-[calc(100vh-100px)] justify-center items-center">
            <CircularProgress
              sx={{ color: "#33c7b6" }}
              style={{ height: 60, width: 60 }}
            />
          </div>
        ) : (
          <div className="w-full lg:pl-[110px]">
            <div className="pt-8 md:pt-10 space-y-8 p md:space-y-10 pl-6 md:pl-8 lg:pl-4">
              <div className="flex items-start justify-center space-x-2 md:space-x-7 pr-6 md:pr-8">
                <div className="space-y-4">
                  <div className="flex items-start">
                    <img
                      className={
                        "object-cover " +
                        (artwork?.artworkFormat.toLowerCase() == "landscape" ||
                        artwork?.artworkFormat.toLowerCase() == "squared"
                          ? "w-full max-h-[300px] sm:max-h-[400px] md:max-h-[500px] lg:max-h-[700px] "
                          : "w-auto max-h-[400px] sm:max-h-[500px] md:max-h-[600px] lg:max-h-[800px]")
                      }
                      src={artwork?.image}
                      alt=""
                    />

                    {artwork?.artworkFormat.toLowerCase() != "landscape" && (
                      <div className="border border-white rounded-full p-1 lg:p-1.5 cursor-pointer ml-2 md:ml-7">
                        <TfiArrowsCorner
                          className="w-5 h-5 lg:w-6 lg:h-6 text-white"
                          onClick={() => setOpenFullImageModal(true)}
                        />
                      </div>
                    )}
                  </div>

                  <div className="w-full space-y-3">
                    <div className="space-y-2 text-white">
                      <div className="flex space-x-3 sm:space-x-6 items-center justify-between sm:justify-start">
                        <p className="text-[28px] lg:text-[36px] font-medium leading-none">
                          {artwork?.name}

                          {!artwork?.artworkSizeSoldOut && (
                            <span className="font-bold text-[21px] pl-3 sm:pl-6">
                              {artwork?.artworkId + "/" + artwork?.artworkSize}
                            </span>
                          )}
                        </p>

                        {!artwork?.artworkSizeSoldOut && (
                          <div className="text-sm sm:text-base">
                            {artwork?.bestSeller ? (
                              <div className="flex items-center h-[29px] bg-[#33C7B6] text-[#00263A] font-source px-4 rounded-full font-bold">
                                Best seller
                              </div>
                            ) : artwork?.nftsLeft?.state ? (
                              <div className="flex items-center h-[29px] bg-[#FFE587] text-[#00263A] font-source px-4 rounded-full font-bold">
                                {artwork?.nftsLeft?.nbNftsLeft +
                                  (artwork?.nftsLeft?.nbNftsLeft > 1
                                    ? " NFTs left"
                                    : " NFT left")}
                              </div>
                            ) : artwork?.theGalleryChoice ? (
                              <div className="flex items-center h-[29px] bg-[#EB9F9F] text-[#00263A] font-source px-4 rounded-full font-bold">
                                Our choice
                              </div>
                            ) : artwork?.mostValued ? (
                              <div className="flex items-center h-[29px] bg-[#E19AEE] text-[#00263A] font-source px-4 rounded-full font-bold">
                                Most valued
                              </div>
                            ) : null}
                          </div>
                        )}
                      </div>

                      <p className="text-[21px] font-medium leading-6 capitalize lg:no-underline lg:hover:underline">
                        <Link
                          to={`/artist/${
                            artwork?.artistId
                          }/collection/${encodeURIComponent(
                            artwork?.collectionName
                          )}`}
                        >
                          {artwork?.collectionName}
                        </Link>
                      </p>

                      <p className="text-[#33c7b6] text-[18px] leading-6 font-medium capitalize lg:no-underline lg:hover:underline">
                        <Link to={`/artist/${artwork?.artistId}`}>
                          {artwork?.artistFirstName + " " + artwork?.artistName}
                        </Link>
                      </p>
                    </div>

                    <div className="space-y-3 lg:space-y-0 lg:flex lg:space-x-32 lg:justify-between text-white w-full">
                      <div className="flex-1">
                        <p className="text-xl">{artwork?.description}</p>
                      </div>

                      <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3 sm:items-center">
                        {!artwork?.artworkSizeSoldOut && (
                          <div className="flex space-x-2">
                            <p className="text-[19px] lg:text-[24px] leading-none font-bold">
                              {totalPriceWithVAT(
                                artwork?.price,
                                artwork?.artworkSize,
                                "EUR"
                              ) + " €"}
                            </p>

                            <p className="flex text-base lg:text-lg text-[#7C7C7C] ">
                              {totalPriceWithVAT(
                                artwork?.price,
                                artwork?.artworkSize,
                                "MATIC"
                              )?.toFixed(2) + " MATIC"}
                            </p>
                          </div>
                        )}

                        <BuyButton artworkData={artwork} design="primary" />
                      </div>
                    </div>
                  </div>
                </div>

                {artwork?.artworkFormat.toLowerCase() == "landscape" && (
                  <div className="border border-white rounded-full p-1 lg:p-1.5 cursor-pointer ml-2 md:ml-7">
                    <TfiArrowsCorner
                      className="w-5 h-5 lg:w-6 lg:h-6 text-white"
                      onClick={() => setOpenFullImageModal(true)}
                    />
                  </div>
                )}
              </div>

              <div className="space-y-7 lg:space-y-10 w-full">
                <div className="space-y-1 pr-6 md:pr-8">
                  <p className="text-[36px] font-bold text-white">
                    Other artworks
                  </p>

                  <div className="bg-white rounded-full h-1 lg:h-2 w-full"></div>
                </div>

                <ArtworksGallery
                  itemsPerPage={screenSize > 768 ? 12 : 7}
                  data={artworks}
                  pagination={true}
                  filterBar={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <PurchaseModals />

      <Modal
        open={openFullImageModal}
        onClose={() => {
          setOpenFullImageModal(false);
        }}
        className="flex-1 bg-black"
      >
        <div className="relative flex justify-center items-center w-full h-full">
          <img
            className="object-contain w-full lg:max-h-[90%]"
            src={artwork?.image}
            alt=""
          />

          <div className="absolute top-6 right-6 border-2 border-white rounded-full p-1 cursor-pointer">
            <RxCross2
              className="sm:w-6 sm:h-6 w-7 h-7 cursor-pointer text-white"
              onClick={() => setOpenFullImageModal(false)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ArtistArtwork;
