import React from "react";
import { Link } from "react-router-dom";

const ArtCard = ({
  image,
  imageLink,
  alt,
  title,
  collection,
  description,
  price,
  button,
  showButton = true,
  design = "primary",
}) => {
  const imageElement = (
    <img
      src={image}
      className="flex max-h-full w-full h-64 object-cover transition-transform duration-300 hover:scale-90"
      alt={alt}
    />
  );

  return (
    <div className="text-white w-full h-full space-y-4">
      <div className="space-y-2">
        {imageLink ? (
          <Link to={imageLink}>{imageElement}</Link>
        ) : (
          <>{imageElement}</>
        )}

        <div className="text-white capitalize leading-6">
          {title && (
            <p
              className={`hover:underline ${
                design === "primary"
                  ? "text-base line-clamp-1"
                  : "text-2xl line-clamp-1"
              }`}
            >
              {title}
            </p>
          )}

          {collection && (
            <p
              className={`hover:underline ${
                design === "primary"
                  ? "text-base line-clamp-1"
                  : "text-2xl line-clamp-1"
              }`}
            >
              {collection}
            </p>
          )}

          {description && (
            <p
              className={`${
                design === "primary"
                  ? "line-clamp-2"
                  : "font-playfair italic line-clamp-3 lg:line-clamp-4"
              }`}
            >
              {description}
            </p>
          )}

          {price && <p>{price}</p>}
        </div>
      </div>

      {showButton && <div className="flex items-start">{button}</div>}
    </div>
  );
};

export default ArtCard;
