import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./hooks/usePrivateRoute";
import HomeV2 from "./pages/HomeV2";
import Artist from "./pages/Artist";
import ArtistDescription from "./pages/ArtistDescription";
import ArtistArtwork from "./pages/ArtistArtwork";
import ArtistCollection from "./pages/ArtistCollection";
import Profile from "./pages/Profile";
import Register from "./pages/Register";
import PrivateSales from "./pages/PrivateSales";
import useAuth from "./hooks/useAuth";
import { PurchaseProvider } from "./hooks/usePurchase";
import Modal from "@mui/material/Modal";
import { IoIosAlert } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { SlCheck } from "react-icons/sl";
import Exhibition from "./pages/Exhibition";

export default function App() {
  const {
    setOpenVerifyMetamaskExtensionModal,
    openVerifyMetamaskExtensionModal,
    openDeleteAccountSuccessModal,
    setOpenDeleteAccountSuccessModal,
    setOpenSwitchNetworkModal,
    openSwitchNetworkModal,
    SwitchNetwork,
    Logout,
  } = useAuth();

  return (
    <>
      <div className="flex-1 relative">
        <Routes>
          <Route
            path="/"
            element={
              <PurchaseProvider>
                <HomeV2 />
              </PurchaseProvider>
            }
          />
          <Route
            path="/artist/:artistId"
            element={
              <PurchaseProvider>
                <Artist />
              </PurchaseProvider>
            }
          />
          <Route
            path="/artist/:artistId/description"
            element={<ArtistDescription />}
          />
          <Route
            path="/artist/:artistId/artwork/:artworkName"
            element={
              <PurchaseProvider>
                <ArtistArtwork />
              </PurchaseProvider>
            }
          />
          <Route
            path="/artist/:artistId/collection/:collectionName"
            element={<ArtistCollection />}
          />
          <Route element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route path="/register" element={<Register />} />
          <Route path="/private-sales" element={<PrivateSales />} />
          <Route
            path="/artist/:artistId/exhibition/:exhibitionId"
            element={<Exhibition />}
          />
        </Routes>
      </div>

      <Modal
        open={openVerifyMetamaskExtensionModal}
        onClose={() => {
          setOpenVerifyMetamaskExtensionModal(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl overflow-auto scrollbar-hide w-full sm:w-[550px] h-full sm:h-[770px] outline-none relative space-y-20">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenVerifyMetamaskExtensionModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-14 px-6 sm:px-16 items-center text-center pb-8">
            <div className="flex flex-col space-y-3 items-center">
              <IoIosAlert className="h-14 w-14 text-red-400" />

              <p className="text-[#00263a] text-[24px] font-black">
                No Metamask extensions detected
              </p>
            </div>

            <div className="flex flex-col space-y-8 items-center">
              <p className="text-[24px] text-center font-source">
                You need Metamask extension to continue browsing
              </p>

              <a
                className="flex bg-[#00263a] items-center font-source px-6 py-2 rounded-full cursor-pointer text-white"
                href="https://metamask.io/download/"
                target="_blank"
                rel="noreferrer"
              >
                Install Metamask
                <img
                  className="w-5 ml-2"
                  src="/images/icon-metamask.png"
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openSwitchNetworkModal}
        onClose={() => {
          setOpenSwitchNetworkModal(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl overflow-auto scrollbar-hide w-full sm:w-[550px] h-full sm:h-[770px] outline-none relative space-y-20">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenSwitchNetworkModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-14 px-6 items-center">
            <div className="flex flex-col space-y-3 items-center">
              <IoIosAlert className="h-14 w-14 text-red-400" />

              <p className="text-[#00263a] text-[24px] font-bold text-center">
                The Gallery <br /> only supports in Polygon
              </p>
            </div>

            <div className="flex flex-col space-y-3 items-center pb-8">
              <button
                className="flex bg-[#00263a] py-2 font-source w-[168px] justify-center rounded-full cursor-pointer text-white"
                onClick={SwitchNetwork}
              >
                Switch network
              </button>

              <button
                className="flex border font-source border-[#00263a] text-[#00263a] w-[168px] justify-center py-1.5 rounded-full cursor-pointer"
                onClick={() => {
                  Logout();
                  setOpenSwitchNetworkModal(false);
                }}
              >
                Disconnect wallet
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openDeleteAccountSuccessModal}
        onClose={() => {
          setOpenDeleteAccountSuccessModal(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl overflow-auto scrollbar-hide w-full sm:w-[520px] h-full sm:h-[770px] outline-none relative space-y-20 sm:space-y-36">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenDeleteAccountSuccessModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-6 px-6 items-center text-center pb-8">
            <SlCheck className="h-10 w-10 text-[#33C7B6]" />

            <div className="space-y-10 items-center text-[#00263a] ">
              <p className="text-[24px] font-black">
                Your account has been <br />
                successfully deleted
              </p>

              <p className="font-source">
                Your data has been deleted.
                <br />
                You can find your NFT in your wallet.
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
