import React from "react";
import { TG_LP_URL } from "../globalVariables";

const SocialMedia = () => {
  return (
    <div className="flex lg:flex-col text-white space-x-2 lg:space-x-0 lg:space-y-2 items-center">
      <div className="p-1 bg-white rounded-[4px]">
        <a
          href="https://thegallery.meeds.io/portal/public/overview"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/Meeds.png" className="w-5 h-5" />
        </a>
      </div>

      <a
        href="https://twitter.com/TGallery_NFT"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/images/Twitter.png" className="w-7 h-7" />
      </a>

      <a
        href="https://www.instagram.com/the_gallery_nft/"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/images/Instagram.png" className="w-7 h-7 rounded-[4px]" />
      </a>

      <a
        href="https://www.facebook.com/TGalleryNFT"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/images/Facebook.png" className="w-7 h-7 rounded-[4px]" />
      </a>

      <a
        href="https://www.linkedin.com/company/thenftgallery/"
        target="_blank"
        rel="noreferrer"
      >
        <img src="/images/Linkedin.png" className="w-7 h-7 rounded-[4px]" />
      </a>

      <a
        href={`${TG_LP_URL}/TheGallery-Whitepaper.pdf`}
        target="_blank"
        rel="noreferrer"
      >
        <img src="/images/Whitepaper.png" className="w-7 h-7 rounded-[4px]" />
      </a>

      <a href={`${TG_LP_URL}/faq`} target="_blank" rel="noreferrer">
        <img src="/images/FAQ.png" className="w-7 h-7 rounded-[4px]" />
      </a>
    </div>
  );
};

export default SocialMedia;
