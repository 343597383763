import React from "react";
import Gallery3D from "../components/Gallery3D";
import { useParams } from "react-router-dom";

const Exhibition = () => {
  const { artistId, exhibitionId } = useParams();

  return (
    <div className="flex flex-grow h-screen w-screen">
      <Gallery3D
        artistId={artistId}
        exhibitionId={exhibitionId}
        fullSize={true}
      />
    </div>
  );
};

export default Exhibition;
