import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BsCreditCard } from "react-icons/bs";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import { RxCross2 } from "react-icons/rx";
import { IoIosAlert } from "react-icons/io";
import useAuth from "../hooks/useAuth";
import usePurchase from "../hooks/usePurchase";
import { useExchangeRate } from "../hooks/usePriceConversion";

const PurchaseModals = () => {
  const { moreInfoCurrentUser, setOpenPaymentModal, openPaymentModal } =
    useAuth();
  const {
    currentPurchasedArtwork,
    openIsPendingModal,
    setOpenIsPendingModal,
    openSuccessfulModal,
    setOpenSuccessfulModal,
    openErrorModal,
    setOpenErrorModal,
    openReservedErrorModal,
    setOpenReservedErrorModal,
    setTermsAndConditions,
    termsError,
    setTermsError,
    buyArtworkByWallet,
    buyArtworkByCard,
  } = usePurchase();
  const { totalPriceWithVAT } = useExchangeRate();
  const priceWithVAT =
    currentPurchasedArtwork?.artistId === "Tourisme-Cotentin"
      ? 60
      : totalPriceWithVAT(
          currentPurchasedArtwork?.price,
          currentPurchasedArtwork?.artworkSize,
          "EUR"
        );

  const refreshPage = () => {
    window.location.reload(false);
  };

  return (
    <>
      <Modal
        open={openPaymentModal}
        onClose={() => {
          setOpenPaymentModal(false);
          setTermsError(false);
          setTermsAndConditions(false);
        }}
        className="flex items-center justify-center"
      >
        <div
          className={`flex flex-col bg-white sm:rounded-xl max-h-full overflow-auto scrollbar-hide w-full sm:w-[550px] h-full outline-none ${
            moreInfoCurrentUser?.provider === "firebase"
              ? "sm:h-[750px]"
              : "sm:h-[800px]"
          }`}
        >
          <div className="flex p-6 w-full justify-end">
            <RxCross2
              className="w-11 h-11 cursor-pointer"
              onClick={() => {
                setOpenPaymentModal(false);
                setTermsError(false);
                setTermsAndConditions(false);
              }}
            />
          </div>

          <div
            className={
              "flex flex-col space-y-10 text-[#00263a] px-6 " +
              (currentPurchasedArtwork?.artworkFormat.toLowerCase() ==
                "landscape" ||
              currentPurchasedArtwork?.artworkFormat.toLowerCase() == "squared"
                ? "px-10 sm:px-[75px]"
                : "px-14 sm:px-[125px]")
            }
          >
            <div className="flex flex-col space-y-14 items-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />

              <p className="text-[24px] font-black text-center">
                Soon in your collection
              </p>
            </div>

            <div className="flex flex-col space-y-6">
              <div className="flex items-center space-x-4">
                <div
                  className={
                    "flex shrink-0 " +
                    (currentPurchasedArtwork?.artworkFormat.toLowerCase() ==
                      "landscape" ||
                    currentPurchasedArtwork?.artworkFormat.toLowerCase() ==
                      "squared"
                      ? "w-[200px]"
                      : "w-[150px]")
                  }
                >
                  <img
                    className="h-auto w-full"
                    src={
                      currentPurchasedArtwork?.artistId === "Tourisme-Cotentin"
                        ? "https://firebasestorage.googleapis.com/v0/b/tg-prod-app-bucket/o/static-artists%2FTourisme-Cotentin%2Ftourisme-cotentin.jpg?alt=media&token=7ce513e3-c507-482e-9b88-d096fb6ed829"
                        : currentPurchasedArtwork?.image
                    }
                    alt=""
                  />
                </div>

                <div className="flex flex-col space-y-1">
                  <p className="font-black leading-[30px] font-source text-[24px]">
                    {currentPurchasedArtwork?.name}
                  </p>

                  <p className="font-source text-base sm:text-[20px]">
                    {priceWithVAT + " €"}
                  </p>
                </div>
              </div>

              <div className="font-source w-full max-w-[370px]">
                <div className="flex space-x-2">
                  <Checkbox
                    sx={{
                      color: "silver",
                      "&.Mui-checked": { color: "#00263a" },
                    }}
                    onClick={(event) =>
                      setTermsAndConditions(event.target.checked)
                    }
                  />

                  <p className="">
                    I have read and accept the{" "}
                    <Link
                      className="underline cursor-pointer"
                      to={`/CGV-CGU-THEGALLERY_V1.0.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      general terms and conditions
                    </Link>{" "}
                    of the sales.
                    <br />
                    J'ai lu et j'accepte les{" "}
                    <Link
                      className="underline cursor-pointer"
                      to={`/CGV-CGU-THEGALLERY_V1.0-fr.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      conditions générales
                    </Link>{" "}
                    de ventes.
                  </p>
                </div>

                {termsError && (
                  <div className="">
                    <span className="block text-sm pt-2 font-medium text-[#FD866C]">
                      The Terms of Service and CGV must be accepted
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-center pb-8">
              <div className="space-y-4">
                {moreInfoCurrentUser?.provider === "moralis" && (
                  <button
                    className="flex w-full bg-[#00263a] space-x-3 px-8 font-source py-2 rounded-full cursor-pointer text-white items-center justify-center"
                    onClick={buyArtworkByWallet}
                  >
                    <span>Payment</span>

                    <img
                      className="w-5"
                      src="/images/icon-metamask.png"
                      alt=""
                    />
                  </button>
                )}

                <button
                  className="flex border-2 border-[#00263a] space-x-3 text-[#00263a] px-8 font-source py-1.5 rounded-full cursor-pointer items-center justify-center"
                  onClick={buyArtworkByCard}
                >
                  <span>Pay by card</span>

                  <BsCreditCard className="w-5 h-5 text-[#00263a]" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openSuccessfulModal}
        onClose={() => {
          setOpenSuccessfulModal(false);
          setOpenPaymentModal(false);
          refreshPage();
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl max-h-full overflow-auto scrollbar-hide w-full sm:w-[550px] h-full sm:h-[770px] outline-none">
          <div className="flex p-6 w-full justify-end">
            <RxCross2
              className="w-11 h-11 cursor-pointer"
              onClick={() => {
                setOpenSuccessfulModal(false);
                setOpenPaymentModal(false);
                refreshPage();
              }}
            />
          </div>

          <div className="flex flex-col space-y-20 text-[#00263a]">
            <div className="flex space-x-1 items-center justify-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />
            </div>

            <div className="flex flex-col space-y-24 px-6 sm:px-16 items-center">
              <div className="flex flex-col items-center space-y-6">
                <AiOutlineCheckCircle className="w-12 h-12 text-[#33c7b6]" />

                <p className="text-[24px]">Successful payment</p>

                <p className="text-[28px] font-bold text-center">
                  Thank you for your purchase !
                </p>
              </div>

              <div className="flex flex-col space-y-3 items-center font-source pb-8">
                <Link
                  to="/profile"
                  className="flex bg-[#00263a] w-full px-3 py-2 rounded-full cursor-pointer text-white justify-center"
                >
                  See your Artwork
                </Link>

                <Link
                  className="px-3 py-1.5 border-2 border-[#00263a] rounded-full"
                  to="/"
                >
                  Back to home page
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openErrorModal}
        onClose={() => {
          setOpenErrorModal(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl max-h-full overflow-auto scrollbar-hide w-full sm:w-[550px] h-full sm:h-[800px] outline-none relative space-y-36">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenErrorModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-10 px-6 items-center text-center">
            <IoIosAlert className="h-14 w-14 text-[#FD866C]" />

            <div className="space-y-2 items-center text-[#00263a] pb-8">
              <p className="text-[24px] font-black">An error has occurred.</p>

              <p className="text-[24px] font-black">
                Please try again later...
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openReservedErrorModal}
        onClose={() => {
          setOpenReservedErrorModal(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl max-h-full overflow-auto scrollbar-hide w-full sm:w-[550px] h-full sm:h-[800px] outline-none relative space-y-32">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenReservedErrorModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-14 px-6 items-center text-center pb-8">
            <div className="space-y-4 items-center">
              <p className="text-[#FD866C] text-[24px] font-black">
                Oops, one second too late
              </p>

              <p className="text-[#FD866C] font-source">
                Another collector has already clicked on <br /> this purchase.
              </p>
            </div>

            <p className="text-[#00263a] font-source">
              You are in the waiting list to purchase this NFT. <br />
              The artwork is reserved for 3 minutes.
            </p>
          </div>
        </div>
      </Modal>

      <Modal
        open={openIsPendingModal}
        onClose={() => {
          setOpenIsPendingModal(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl max-h-full overflow-auto scrollbar-hide w-full sm:w-[520px] h-full sm:h-[570px] outline-none relative space-y-20">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenIsPendingModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-20 items-center px-6">
            <p className="text-[#00263a] text-[24px] font-bold text-center">
              Purchase in progress, please wait.
            </p>

            <CircularProgress
              sx={{ color: "#33c7b6" }}
              style={{ height: 60, width: 60 }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default PurchaseModals;
