import React, { useState, useEffect } from "react";
import Close from "../components/Close";
import validator from "validator";
import Checkbox from "@mui/material/Checkbox";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const Register = () => {
  const { state } = useLocation();
  const [error, setError] = useState("");
  const [errorSuccess, setErrorSuccess] = useState("");
  const [permission, setPermission] = useState(false);
  const [contactInformations, setContactInformations] = useState({
    type: "artist",
    firstname: state?.name ? state?.name : "",
    lastName: "",
    artistName: "",
    book: "",
    mail: "",
    phone: "000",
  });

  const sendSubscription = () => {
    if (
      contactInformations.mail !== "" &&
      contactInformations.artistName !== "" &&
      contactInformations.book !== ""
    ) {
      if (validator.isEmail(contactInformations.mail) === true) {
        fetch(`https://api.the-gallery.io/prospects`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(contactInformations),
        })
          .then(() => {
            setErrorSuccess("200");
          })
          .catch((error) => {
            setErrorSuccess("400");
          });
      } else {
        setError("Email is invalid.");
      }
    } else {
      setError("Please complete all required fields");
    }
  };

  useEffect(() => {
    return window.scrollTo(0, 0);
  }, [errorSuccess]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <motion.div
      id="inscription"
      initial={{ width: 0, opacity: 0 }}
      animate={{ width: "100%", opacity: 1 }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 }, opacity: 0 }}
    >
      <div className="relative h-[200px] sm:h-[300px] w-full p-6 md:p-8 lg:p-4 lg:max-w-[1440px] lg:mx-auto">
        <img
          className="object-cover h-full w-full"
          src="/images/privacy.png"
          alt="page cover"
        />

        <Close elem="v1" />
      </div>

      <div className="flex max-w-[720px] text-white space-x-6 px-8 md:px-10 -mt-28 sm:-mt-36 z-50">
        <div className="bg-white w-3 h-44 rounded-full"></div>

        <div>
          <h2 className="text-[44px] sm:text-[54px] pb-20 pt-6">
            Join the community
          </h2>

          <div className="space-y-16">
            <p className="text-xl font-semibold">
              There no art gallery without amazing creators. While we focus on
              the quality of the art, we strongly promote the need to offer a
              diverse and original sets of creations. Follow-us and tell us more
              about you. We would love to have you on-board of this amazing
              venture
            </p>

            {errorSuccess === "200" ? (
              <div>
                <p className="text-[#33c7b6] text-xl">
                  Thank you! Your submission has been sent.
                </p>
              </div>
            ) : errorSuccess === "400" ? (
              <div>
                <p className="text-red-500 text-xl">
                  An error has occurred please try again.
                </p>
              </div>
            ) : null}

            <div className="space-y-5">
              <h5 className="text-[24px] font-bold">
                My information as an artist
              </h5>

              <div className="space-y-6 md:space-y-0 md:flex md:space-x-8">
                <div className="w-full font-source">
                  <label className="">My first name</label>

                  <div className="flex py-2 px-7 border-2 border-white rounded-full w-full mt-2.5">
                    <input
                      type="text"
                      className="outline-none bg-transparent w-full"
                      placeholder={state?.name ? state?.name : "Ex: Julien"}
                      onChange={(e) =>
                        setContactInformations({
                          ...contactInformations,
                          firstname: e.target.value,
                        })
                      }
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Ex: Julien")}
                    />
                  </div>
                </div>

                <div className="w-full font-source">
                  <label className="">My last name</label>

                  <div className="flex py-2 px-7 border-2 border-white rounded-full w-full mt-2.5">
                    <input
                      type="text"
                      className="outline-none bg-transparent w-full"
                      placeholder="Ex: Dupond"
                      onChange={(e) =>
                        setContactInformations({
                          ...contactInformations,
                          lastName: e.target.value,
                        })
                      }
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "Ex: Dupond")}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full md:w-[48%] font-source">
                <label className="">My artist name*</label>

                <div className="flex py-2 px-7 border-2 border-white rounded-full w-full mt-2.5">
                  <input
                    type="text"
                    className="outline-none bg-transparent w-full"
                    placeholder="Ex: StudioArt’s"
                    onChange={(e) =>
                      setContactInformations({
                        ...contactInformations,
                        artistName: e.target.value,
                      })
                    }
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "Ex: StudioArt’s")}
                  />
                </div>
              </div>

              <div className="w-full font-source">
                <label className="">
                  My book * (URL website, social media, other.)
                </label>

                <div className="flex py-2 px-7 border-2 border-white rounded-full w-full mt-2.5">
                  <input
                    type="text"
                    className="outline-none bg-transparent w-full"
                    placeholder="Ex: https://studioArts.com"
                    onChange={(e) =>
                      setContactInformations({
                        ...contactInformations,
                        book: e.target.value,
                      })
                    }
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) =>
                      (e.target.placeholder = "Ex: https://studioArts.com")
                    }
                  />
                </div>
              </div>
            </div>

            <div className="space-y-5">
              <h5 className="text-[24px] font-bold">My contact informations</h5>

              <p className="font-source">
                I am interested by the project and I ‘ld like to be contacted by
                TheGallery Teams to qualify my registration
              </p>

              <div className="space-y-6 md:space-y-0 md:flex md:space-x-8">
                <div className="w-full font-source">
                  <label className="">My email*</label>

                  <div className="flex py-2 px-7 border-2 border-white rounded-full w-full mt-2.5">
                    <input
                      type="mail"
                      className="outline-none bg-transparent w-full"
                      placeholder="Ex: j.dupond@studioarts.com"
                      onChange={(e) =>
                        setContactInformations({
                          ...contactInformations,
                          mail: e.target.value,
                        })
                      }
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = "Ex: j.dupond@studioarts.com")
                      }
                    />
                  </div>
                </div>

                <div className="w-full font-source">
                  <label className="">My phone number</label>

                  <div className="flex py-2 px-7 border-2 border-white rounded-full w-full mt-2.5">
                    <input
                      type="text"
                      className="outline-none bg-transparent w-full"
                      placeholder="Ex: +33 6 12 34 56 78"
                      onChange={(e) =>
                        setContactInformations({
                          ...contactInformations,
                          phone: e.target.value,
                        })
                      }
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) =>
                        (e.target.placeholder = "Ex: +33 6 12 34 56 78")
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="flex space-x-2.5 font-source pt-4">
                <Checkbox
                  sx={{
                    color: "#FFFFFF",
                    "&.Mui-checked": {
                      color: "#FFFFFF",
                    },
                  }}
                  onClick={(event) => setPermission(event.target.checked)}
                />

                <p className="texte-[14px] md:texte-[17px]">
                  I hereby give permission to TheGallery to use my data for the
                  purpose documented and under conditions stated in our Privacy
                  Agreement
                </p>
              </div>

              {error && !errorSuccess && (
                <div>
                  <p className="text-sm text-red-500 mt-2">{error}</p>
                </div>
              )}
            </div>

            <div className="flex w-full justify-center font-source pb-12">
              <button
                className="border-2 border-[#33c7b6] text-[#33c7b6] rounded-full py-2 px-8"
                onClick={sendSubscription}
              >
                Register
              </button>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Register;
