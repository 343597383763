import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMoralisAuth } from "@moralisweb3/client-firebase-auth-utils";
import { getFunctions } from "firebase/functions";

const firebaseConfig =
  process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT === "prod"
    ? process.env.REACT_APP_FIREBASE_CONFIG_PROD
    : process.env.REACT_APP_FIREBASE_CONFIG_STAGING;

const app = initializeApp(JSON.parse(firebaseConfig));
const auth = getAuth(app);
const functions = getFunctions(app);
const db = getFirestore(app);
const storage = getStorage(app);
const moralisAuth = getMoralisAuth(app, {
  auth,
  functions,
});

export { app, moralisAuth, auth, db, storage };
