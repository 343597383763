import React from "react";
import { RxCaretRight, RxCaretLeft } from "react-icons/rx";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CarouselBig = ({ children }) => {
  function CustomLeftArrow({ onCLickHandler }) {
    return (
      <RxCaretLeft
        onClick={onCLickHandler}
        className="cursor-pointer -left-2 sm:left-2 absolute top-[calc(50%-3px)] w-14 h-14 text-white z-10"
      ></RxCaretLeft>
    );
  }

  function CustomRightArrow({ onCLickHandler }) {
    return (
      <RxCaretRight
        onClick={onCLickHandler}
        className="cursor-pointer -right-2 sm:right-2 absolute top-[calc(50%-3px)] w-14 h-14 text-white z-10"
      ></RxCaretRight>
    );
  }

  return (
    <Carousel
      className="[&_.slide]:border-none w-full h-full"
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      showIndicators={false}
      customRightArrow={<CustomRightArrow />}
      renderArrowPrev={(onClickHandler) => (
        <CustomLeftArrow onCLickHandler={onClickHandler} />
      )}
      renderArrowNext={(onClickHandler) => (
        <CustomRightArrow onCLickHandler={onClickHandler} />
      )}
    >
      {children}
    </Carousel>
  );
};

export default CarouselBig;
