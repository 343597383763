import React, { useRef, useState } from "react";
import validator from "validator";
import { SERVER_URL } from "../../globalVariables";
import emailjs from "@emailjs/browser";

const Newsletter = ({ isInModal }) => {
  const [error, setError] = useState("");
  const form = useRef();
  const [contactInformations, setContactInformations] = useState({
    email: "",
  });

  const sendSubscription = (e) => {
    e.preventDefault();

    if (contactInformations.email !== "") {
      if (validator.isEmail(contactInformations.email) === true) {
        fetch(`${SERVER_URL}/api/newsletter`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(contactInformations),
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.msg !== "subscribed") {
              emailjs
                .sendForm(
                  "service_j98yodi",
                  "template_ilwensv",
                  form.current,
                  "cKc78rMKbgska16W1"
                )
                .then(
                  () => {
                    setError("200");
                  },
                  (error) => {
                    console.log(error.text);
                  }
                );
            } else {
              setError("You are already subscribed to our newsletter.");
            }
          })
          .catch((error) => {
            setError("An error has occurred! please try again.");
          });
      } else {
        setError("Email is invalid.");
      }
    } else {
      setError("Please complete all required fields");
    }
  };

  return (
    <div
      className={`scrollTo space-y-4  ${
        isInModal ? "text-[#00263a]" : "text-white"
      }`}
      id="newsletter"
    >
      <h3 className="text-6xl font-medium">Newsletter</h3>

      <div
        className={`space-y-3 md:space-y-0 md:flex gap-4 w-full flex-row ${
          isInModal ? "md:flex-col" : "md:flex-row md:space-x-7"
        }`}
      >
        <div className="w-full">
          <p className="text-xl">
            To not miss any pre-sale and to stay up to date with TheGallery
            subscribe to the Newsletter
          </p>
        </div>

        <div className="space-y-3 md:space-y-4 w-full">
          <form
            ref={form}
            onSubmit={sendSubscription}
            className="space-y-3 md:space-y-0 md:flex md:space-x-5 "
          >
            <div
              className={`flex py-2 px-7 border-2 ${
                isInModal ? "border-[#33c7b6] text-[#00263a]" : "border-white"
              }  rounded-full w-full`}
            >
              <input
                type="email"
                name="user_email"
                className={`outline-none bg-transparent w-full ${
                  isInModal
                    ? "placeholder:text-[#00263a]"
                    : "placeholder:text-white"
                } text-[17px]`}
                placeholder={"My email address"}
                onFocus={(e) => (e.target.placeholder = "")}
                onBlur={(e) => (e.target.placeholder = "My email address")}
                onChange={(event) => {
                  setContactInformations({
                    email: event.target.value,
                  });
                }}
              />
            </div>

            <div className="flex justify-end">
              <input
                type="submit"
                value="Subscribe"
                className="border-2 border-[#33c7b6] rounded-full text-[#33c7b6] text-[17px] px-7 py-2 cursor-pointer"
              />
            </div>
          </form>

          {error === "200" ? (
            <div>
              <p className="text-sm text-[#33c7b6]">
                Thank you! Your submission has been sent.
              </p>
            </div>
          ) : (
            <div>
              <p className="text-sm text-red-500">{error}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
