export const ENVIRONMENT = process.env.REACT_APP_DEPLOYMENT_ENVIRONMENT;

export const TG_LP_URL =
  ENVIRONMENT === "prod"
    ? process.env.REACT_APP_TG_LP_URL_PROD
    : process.env.REACT_APP_TG_LP_URL_STAGING;

export const TG_APP_URL =
  ENVIRONMENT === "prod"
    ? process.env.REACT_APP_TG_APP_URL_PROD
    : process.env.REACT_APP_TG_APP_URL_STAGING;

export const SERVER_URL =
  ENVIRONMENT === "prod"
    ? process.env.REACT_APP_SERVER_URL_PROD
    : ENVIRONMENT === "staging"
    ? process.env.REACT_APP_SERVER_URL_STAGING
    : "http://localhost:3002";

export const SM_MERCHANT_ADDRESS =
  ENVIRONMENT == "prod"
    ? process.env.REACT_APP_SM_MERCHANT_ADDRESS_PROD
    : process.env.REACT_APP_SM_MERCHANT_ADDRESS_STAGING;

export const SM_SALE_ADDRESS =
  ENVIRONMENT == "prod"
    ? process.env.REACT_APP_SM_SALE_ADDRESS_PROD
    : process.env.REACT_APP_SM_SALE_ADDRESS_STAGING;

export const SM_MINT_ADDRESS =
  ENVIRONMENT == "prod"
    ? process.env.REACT_APP_SM_MINT_ADDRESS_PROD
    : process.env.REACT_APP_SM_MINT_ADDRESS_STAGING;

export const TG_WALLET_ADDRESS =
  ENVIRONMENT == "prod"
    ? process.env.REACT_APP_TG_WALLET_ADDRESS_PROD
    : process.env.REACT_APP_TG_WALLET_ADDRESS_STAGING;

export const VAT_WALLET =
  ENVIRONMENT == "prod"
    ? process.env.REACT_APP_VAT_WALLET_PROD
    : process.env.REACT_APP_VAT_WALLET_STAGING;

export const chainId = ENVIRONMENT == "prod" ? 137 : 80002;

export const chainIdHex = ENVIRONMENT == "prod" ? "0x89" : "0x13882";

export const SM_PRESALE_ADDRESS = process.env.REACT_APP_SM_PRESALE_ADDRESS;

export const cardServiceFees = 4;
