import React, {
  useState,
  useEffect,
  useMemo,
  createContext,
  useContext,
} from "react";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  onAuthStateChanged,
  reauthenticateWithCredential,
  EmailAuthProvider,
  signOut,
  deleteUser,
} from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { signInWithMoralis } from "@moralisweb3/client-firebase-evm-auth";
import { auth, moralisAuth, db } from "../firebase";
import { isBrowser } from "react-device-detect";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { TG_APP_URL, ENVIRONMENT, chainIdHex } from "../globalVariables";

const AuthContext = createContext({});

export function AuthProvider({ children }) {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [moreInfoCurrentUser, setMoreInfoCurrentUser] = useState(null);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [timeActive, setTimeActive] = useState(false);
  const [buyNowActive, setBuyNowActive] = useState(false);
  const [openUpdateUserDataModal, setOpenUpdateUserDataModal] = useState(false);
  const [openConnexionModal, setOpenConnexionModal] = useState(false);
  const [openSignInModal, setOpenSignInModal] = useState(false);
  const [openSignUpModal, setOpenSignUpModal] = useState(false);
  const [openConfirmEmailModal, setOpenConfirmEmailModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openReauthenticateModal, setOpenReauthenticateModal] = useState(false);
  const [
    openVerifyMetamaskExtensionModal,
    setOpenVerifyMetamaskExtensionModal,
  ] = useState(false);
  const [openSwitchNetworkModal, setOpenSwitchNetworkModal] = useState(false);
  const [openDeleteAccountSuccessModal, setOpenDeleteAccountSuccessModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { address, isConnected } = useAccount();
  const { connect } = useConnect({
    connector: new InjectedConnector(),
  });
  const { disconnect } = useDisconnect();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        let docRef = doc(db, "users", user.uid);
        let docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setMoreInfoCurrentUser(docSnap.data());
        }

        setCurrentUser(user);

        setIsLoading(false);

        if (!isConnected) {
          connect();
        }
      } else {
        setCurrentUser(null);
        setMoreInfoCurrentUser(null);
        setIsLoading(false);
      }
    });
  }, [currentUser]);

  const VerifyMetamaskExtension = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      return LoginWithWithMoralis();
    } else {
      if (isBrowser) {
        return setOpenVerifyMetamaskExtensionModal(true);
      } else {
        window.location.replace(`https://metamask.app.link/dapp/${TG_APP_URL}`);
      }
    }
  };

  const SwitchNetwork = () => {
    window.ethereum
      .request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainIdHex }],
      })
      .then(() => {
        setOpenSwitchNetworkModal(false);
      })
      .catch((switchError) => {
        if (
          switchError.code === 4902 ||
          switchError.data?.originalError?.code === 4902
        ) {
          window.ethereum
            .request({
              method: "wallet_addEthereumChain",
              params: [
                ENVIRONMENT == "prod"
                  ? {
                      chainId: "0x89",
                      chainName: "Polygon",
                      nativeCurrency: {
                        name: "MATIC",
                        decimals: 18,
                        symbol: "MATIC",
                      },
                      rpcUrls: ["https://polygon-rpc.com"],
                      blockExplorerUrls: ["https://polygonscan.com/"],
                    }
                  : {
                      chainId: "0x13882",
                      chainName: "Polygon Amoy Testnet",
                      nativeCurrency: {
                        name: "MATIC",
                        decimals: 18,
                        symbol: "MATIC",
                      },
                      rpcUrls: ["https://rpc-amoy.polygon.technology"],
                      blockExplorerUrls: ["https://amoy.polygonscan.com"],
                    },
              ],
            })
            .then(() => {
              setOpenSwitchNetworkModal(false);
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (switchError.code === 4001) {
          setOpenSwitchNetworkModal(true);
        } else {
          console.log(switchError);
        }
      });
  };

  const LoginWithWithMoralis = async (params) => {
    setIsLoading(true);

    signInWithMoralis(moralisAuth)
      .then(async (res) => {
        connect();

        let docRef = doc(db, "users", res.credentials.user.uid);
        let docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          setDoc(doc(db, "users", res.credentials.user.uid), {
            uid: auth.currentUser.uid,
            walletAddress: res.credentials.user.displayName,
            firstName: "",
            lastName: "",
            birthDate: { month: "", day: "", year: "" },
            email: "",
            profilePicture: "",
            coverPicture: "",
            provider: "moralis",
            updateTime: "",
            creationTime: serverTimestamp(),
          })
            .then(() => {})
            .catch((error) => {
              console.log(error.code);
            });
        }

        if (openConnexionModal) {
          setOpenConnexionModal(false);
        }

        if (buyNowActive) {
          setOpenPaymentModal(true);
        }

        if (window.ethereum.chainId !== chainIdHex) {
          setOpenSwitchNetworkModal(true);
        }

        if (params === "delete account") {
          deleteAccount();
        }
      })
      .catch((error) => {
        setError(error);
        console.log(error);
      });
  };

  const RegisterWithEmailAndPassword = (name, birthDate, email, password) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((res) => {
        let docRef = doc(db, "users", auth.currentUser.uid);

        setDoc(docRef, {
          uid: auth.currentUser.uid,
          firstName: name.firstName,
          lastName: name.lastName,
          birthDate: birthDate,
          email: email,
          walletAdress: "",
          profilePicture: "",
          coverPicture: "",
          provider: "firebase",
          updateTime: "",
          creationTime: serverTimestamp(),
        })
          .then(() => {
            setOpenSignUpModal(false);
            setOpenConfirmEmailModal(true);
            ResendEmailVerification();
          })
          .catch((error) => {
            setError("An error has occurred. Please retry again...");
            console.log(error.code);
          });
      })
      .catch((error) => {
        setError("An account already exists with the same email address.");
        console.log(error.code);
      });
  };

  const ResendEmailVerification = () => {
    sendEmailVerification(auth.currentUser)
      .then(() => {
        setTimeActive(true);
      })
      .catch((error) => {
        console.log(error.code);
      });
  };

  const UserReload = () => {
    currentUser
      ?.reload()
      .then(() => {
        if (currentUser.emailVerified) {
          setTimeActive(false);
          setOpenConfirmEmailModal(false);

          if (buyNowActive) {
            setOpenPaymentModal(true);
          } else {
            navigate("/profile");
          }
        }
      })
      .catch((error) => {
        console.log(error.code);
      });
  };

  const LoginWithEmailAndPassword = (email, password) => {
    if (email === "" || password === "") {
      setError("Please complete all required fields.");
    } else {
      signInWithEmailAndPassword(auth, email, password)
        .then((res) => {
          setOpenSignInModal(false);
          setError("");

          if (buyNowActive) {
            setOpenPaymentModal(true);
          }
        })
        .catch((error) => {
          setError(
            "The email address or password is incorrect. Please retry again..."
          );
          console.log(error.code);
        });
    }
  };

  const PasswordReset = (email) => {
    sendPasswordResetEmail(auth, email)
      .then((res) => {
        setResult(
          "If your email exists in our database, you will receive an email within a few minutes telling you how to reset your password."
        );
      })
      .catch((error) => {
        setError("An error has occurred. Please retry again...");
        console.log(error.code);
      });
  };

  const UpdatePassword = (newPassword) => {
    updatePassword(auth.currentUser, newPassword)
      .then(() => {
        setOpenUpdateUserDataModal(false);
        setResult("your password has been changed successfully");
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          setOpenReauthenticateModal(true);
        } else {
          setError("An error has occurred. Please retry again...");
        }
      });
  };

  const UpdateEmail = (newEmail) => {
    if (moreInfoCurrentUser?.provider === "moralis") {
      UpdateUserData("email", newEmail);
    } else {
      updateEmail(auth.currentUser, newEmail)
        .then(() => {
          UpdateUserData("email", newEmail);
          ResendEmailVerification();
          setOpenConfirmEmailModal(true);
        })
        .catch((error) => {
          if (error.code === "auth/requires-recent-login") {
            setOpenReauthenticateModal(true);
          } else {
            setError("An error has occurred. Please retry again...");
          }
        });
    }
  };

  const reauthenticateUser = (password) => {
    const credential = EmailAuthProvider.credential(
      auth.currentUser.email,
      password
    );

    reauthenticateWithCredential(auth.currentUser, credential)
      .then(() => {
        setOpenReauthenticateModal(false);
        setError("");
      })
      .catch((error) => {
        setError("The password is incorrect. Please retry again...");
        console.log(error.code);
      });
  };

  const UpdateUserData = (field, newData) => {
    const userRef = doc(db, "users", auth.currentUser.uid);
    let data = {};

    if (field === "profilePicture") {
      data = { profilePicture: newData, updateTime: serverTimestamp() };
      setMoreInfoCurrentUser({
        ...moreInfoCurrentUser,
        profilePicture: newData,
      });
    } else if (field === "coverPicture") {
      data = { coverPicture: newData, updateTime: serverTimestamp() };
      setMoreInfoCurrentUser({ ...moreInfoCurrentUser, coverPicture: newData });
    } else if (field === "name") {
      data = {
        firstName: newData.firstName,
        lastName: newData.lastName,
        updateTime: serverTimestamp(),
      };
      setMoreInfoCurrentUser({
        ...moreInfoCurrentUser,
        firstName: newData.firstName,
        lastName: newData.lastName,
      });
    } else if (field === "email") {
      data = { email: newData, updateTime: serverTimestamp() };
      setMoreInfoCurrentUser({ ...moreInfoCurrentUser, email: newData });
    } else if (field === "birthDate") {
      data = { birthDate: newData, updateTime: serverTimestamp() };
      setMoreInfoCurrentUser({ ...moreInfoCurrentUser, birthDate: newData });
    }

    updateDoc(userRef, data)
      .then((res) => {
        setOpenUpdateUserDataModal(false);
      })
      .catch((error) => {
        setError("An error has occurred. Please retry again...");
        console.log(error.code);
      });
  };

  const Logout = async () => {
    signOut(auth)
      .then((res) => {
        disconnect();

        if (buyNowActive) {
          setOpenPaymentModal(false);
        }
      })
      .catch((error) => {
        setError("An error has occurred. Please retry again...");
        console.log(error.code);
      });
  };

  const deleteAccount = async () => {
    const uid = auth.currentUser.uid;

    let docRef = doc(db, "users", uid);

    deleteDoc(docRef)
      .then(() => {
        deleteUser(auth.currentUser)
          .then(() => {
            disconnect();
            setOpenDeleteAccountSuccessModal(true);
          })
          .catch((error) => {
            if (error.code === "auth/requires-recent-login") {
              LoginWithWithMoralis("delete account");
            } else {
              setError("An error has occurred. Please retry again...");
              console.log(error);
            }
          });
      })
      .catch((error) => {
        setError("An error has occurred. Please retry again...");
        console.log(error);
      });
  };

  const addArtist = (artistId) => {
    setDoc(doc(db, "artists", artistId), {
      coverPicture: "/ahmed-cover.jpg",
      firstName: "Ahmed",
      lastName: "Bahhodh",
      links: {
        facebook: "https://www.facebook.com/bahhodh/",
        instagram: "https://www.instagram.com/ahmedbahhodh/?hl=fr",
        twitter: "https://twitter.com/bahhodh",
        website: "https://www.ahmedbahhodh.com/",
      },
      profilePicture: "/ahmed-profile.jpeg",
      pseudo: "",
      slogan: "“Inspired by esthetism and beauty”",
      stats: {
        followers: 28,
        sales: 25,
        views: 1520,
      },
      statusPro: "Photographer",
      creationTime: serverTimestamp(),
    })
      .then(() => {})
      .catch((error) => {
        console.log(error.code);
      });
  };

  const addCollection = (collectionId) => {
    setDoc(doc(db, "artists/Ahmed-Bahhodh/collections/", collectionId), {
      artistId: "Ahmed-Bahhodh",
      coverPicture: "/ahmed-cover-collection.jpg",
      description:
        "Description de la collection : Ahmed Bahhodh is a dedicated artist , fascinated since childhood by curves and colours , at first in botanical world and since his studies at Saint Luc Institut in Tournai by those in automobile sector .",
      name: "Collection Lorem Ipsum",
      yearOfProd: 2019,
      creationTime: serverTimestamp(),
      nbOfArtworks: 400,
    })
      .then(() => {})
      .catch((error) => {
        console.log(error.code);
      });
  };

  const memodValue = useMemo(
    () => ({
      currentUser,
      moreInfoCurrentUser,
      error,
      result,
      isLoading,
      timeActive,
      openConnexionModal,
      openSignInModal,
      openSignUpModal,
      openConfirmEmailModal,
      openUpdateUserDataModal,
      openReauthenticateModal,
      openVerifyMetamaskExtensionModal,
      openSwitchNetworkModal,
      openDeleteAccountSuccessModal,
      buyNowActive,
      openPaymentModal,
      setMoreInfoCurrentUser,
      setError,
      setResult,
      setIsLoading,
      setTimeActive,
      setOpenConnexionModal,
      setOpenSignInModal,
      setOpenSignUpModal,
      setOpenConfirmEmailModal,
      setBuyNowActive,
      setOpenPaymentModal,
      setOpenUpdateUserDataModal,
      setOpenReauthenticateModal,
      setOpenVerifyMetamaskExtensionModal,
      setOpenSwitchNetworkModal,
      setOpenDeleteAccountSuccessModal,
      LoginWithWithMoralis,
      LoginWithEmailAndPassword,
      RegisterWithEmailAndPassword,
      ResendEmailVerification,
      UserReload,
      PasswordReset,
      UpdatePassword,
      UpdateEmail,
      UpdateUserData,
      reauthenticateUser,
      Logout,
      deleteAccount,
      VerifyMetamaskExtension,
      SwitchNetwork,
    }),
    [
      currentUser,
      moreInfoCurrentUser,
      error,
      result,
      isLoading,
      timeActive,
      openConnexionModal,
      openSignInModal,
      openSignUpModal,
      openConfirmEmailModal,
      openUpdateUserDataModal,
      openReauthenticateModal,
      openVerifyMetamaskExtensionModal,
      openSwitchNetworkModal,
      openPaymentModal,
      openDeleteAccountSuccessModal,
    ]
  );

  return (
    <AuthContext.Provider value={memodValue}>{children}</AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
