import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { RxCross2 } from "react-icons/rx";
import Newsletter from "./Newsletter";

const NewsletterModal = () => {
  const [openFirstTimeVisitModal, setOpenFirstTimeVisitModal] = useState(false);

  useEffect(() => {
    const checkUserVisitedBefore = setTimeout(() => {
      const visited = !!localStorage.getItem("userVisitedBefore");

      if (!visited) {
        localStorage.setItem("userVisitedBefore", true);
        setOpenFirstTimeVisitModal(true);
      }
    }, 10000);

    return () => clearTimeout(checkUserVisitedBefore);
  }, []);

  return (
    <Modal
      open={openFirstTimeVisitModal}
      onClose={() => setOpenFirstTimeVisitModal(false)}
      className="flex items-center justify-center"
    >
      <div className="flex flex-col bg-white sm:rounded-xl max-h-full p-8 pb-6 overflow-auto scrollbar-hide w-full sm:w-[550px] h-full sm:h-auto outline-none">
        <div className="flex w-full justify-end">
          <RxCross2
            className="w-11 h-11 cursor-pointer text-[#00263a]"
            onClick={() => setOpenFirstTimeVisitModal(false)}
          />
        </div>

        <Newsletter isInModal />
      </div>
    </Modal>
  );
};

export default NewsletterModal;
