import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import Header from "../components/Header";
import StickBar from "../components/StickBar";
import { TfiWorld, TfiFacebook, TfiLinkedin } from "react-icons/tfi";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import VideoPlayer from "../components/VideoPlayer";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import CircularProgress from "@mui/material/CircularProgress";

const ArtistDescription = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [artist, setArtist] = useState(null);
  let { artistId } = useParams();

  useEffect(() => {
    const getArtist = async () => {
      const docRef = collection(db, "artists");
      const q = query(docRef, where("artistId", "==", artistId));
      const querySnapshot = await getDocs(q);

      let artist = null;
      querySnapshot.forEach((doc) => {
        artist = doc.data();
      });

      if (artist) {
        setArtist(artist);
      } else {
        navigate("/");
      }
    };

    getArtist();
  }, [artistId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="lg:max-w-[1440px] lg:mx-auto">
      <Header moreStyle={"sticky"} logo={true} />

      <div className="flex">
        <StickBar moreStyle="z-20" />

        {!artist ? (
          <div className="flex w-full justify-center items-center h-[90vh]">
            <CircularProgress
              sx={{ color: "#33c7b6" }}
              style={{ height: 60, width: 60 }}
            />
          </div>
        ) : (
          <div className="w-full">
            {artist?.videoPresentation ? (
              <>
                <div className="hidden lg:flex">
                  <VideoPlayer
                    path={artist?.videoPresentation}
                    poster={artist?.imageVideoPresentation}
                    autoPlay={true}
                  />
                </div>

                <div className="flex lg:hidden">
                  <VideoPlayer
                    path={artist?.videoPresentation}
                    poster={artist?.imageVideoPresentation}
                    autoPlay={false}
                  />
                </div>
              </>
            ) : (
              <img
                className="w-full max-h-[200px] sm:max-h-[300px] md:max-h-[400px] lg:max-h-[545px]"
                src={artist?.descriptionCoverPicture}
                alt="cover-picture"
              />
            )}

            <div className="w-full lg:pl-[110px]">
              <div className="flex flex-col px-6 md:px-8">
                <div className="text-white space-y-1 md:space-y-3 text-center py-8 md:py-10 lg:py-16">
                  <h1 className="leading-[50px]">
                    {artist?.firstName + " " + artist?.lastName}
                  </h1>

                  <p className="text-[18px] md:text-[19px] lg:text-[24px] font-bold text-center">
                    {artist?.statusPro}
                  </p>

                  <div className="flex space-x-2 lg:space-x-4 justify-center">
                    {artist?.links?.website && (
                      <a
                        className=""
                        href={artist?.links.website}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TfiWorld className="w-5 h-5 lg:w-6 lg:h-6" />
                      </a>
                    )}

                    {artist?.links.instagram && (
                      <a
                        className=""
                        href={artist?.links.instagram}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsInstagram className="w-5 h-5 lg:w-6 lg:h-6" />
                      </a>
                    )}

                    {artist?.links.twitter && (
                      <a
                        className=""
                        href={artist?.links.twitter}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <BsTwitter className="w-5 h-5 lg:w-6 lg:h-6" />
                      </a>
                    )}

                    {artist?.links.linkedin && (
                      <a
                        className=""
                        href={artist?.links.linkedin}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TfiLinkedin className="w-5 h-5 lg:w-6 lg:h-6" />
                      </a>
                    )}

                    {artist?.links.facebook && (
                      <a
                        className=""
                        href={artist?.links.facebook}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TfiFacebook className="w-5 h-5 lg:w-6 lg:h-6 -ml-2" />
                      </a>
                    )}

                    {artist?.links.tiktok && (
                      <a
                        className=""
                        href={artist?.links.tiktok}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaTiktok className="w-5 h-5 lg:w-6 lg:h-6 -ml-2" />
                      </a>
                    )}
                  </div>
                </div>

                {artist?.moreAbout?.section1 && (
                  <div className="lg:flex space-y-10 lg:space-y-0 lg:space-x-16 pb-16 lg:pb-24 lg:justify-center lg:items-center">
                    <div className="flex justify-center lg:justify-end w-full lg:w-1/2">
                      <img
                        className="object-cover w-auto max-h-[250px] md:max-h-[450px]"
                        src={artist?.moreAbout?.section1?.img}
                        alt=""
                      />
                    </div>

                    <div className="text-[#E9E9E9] w-full lg:w-1/2">
                      <div className="w-full">
                        <h3 className="text-[24px] leading-none font-bold pb-6">
                          {artist?.moreAbout?.section1?.title}
                        </h3>

                        <p
                          className="text-justify"
                          dangerouslySetInnerHTML={{
                            __html: artist?.moreAbout?.section1?.desctiption,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {artist?.moreAbout?.section2 && (
                  <div className="md:flex space-y-10 md:space-y-0 md:space-x-16 w-full md:justify-center text-[#E9E9E9] pb-16 lg:pb-24">
                    <div className="w-full md:w-1/2  flex flex-col items-end">
                      <div className="w-full">
                        <h3 className="text-[24px] font-bold pb-6">
                          {artist?.moreAbout?.section2?.leftTitle}
                        </h3>

                        <p
                          className="text-justify"
                          dangerouslySetInnerHTML={{
                            __html: artist?.moreAbout?.section2?.leftText,
                          }}
                        />
                      </div>
                    </div>

                    <div className="w-full md:w-1/2">
                      <div className="w-full">
                        <h3 className="text-[24px] font-bold pb-6">
                          {artist?.moreAbout?.section2?.rightTitle}
                        </h3>

                        <p
                          className="text-justify"
                          dangerouslySetInnerHTML={{
                            __html: artist?.moreAbout?.section2?.rightText,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {artist?.moreAbout?.section3 && (
                  <div className="w-full pb-16 lg:pb-24">
                    <p className="text-[#E9E9E9] font-bold text-[24px] lg:text-[36px] md:text-center pb-6 md:pb-8 lg:pb-16">
                      {artist?.moreAbout?.section3?.title}
                    </p>

                    <div className="lg:flex space-y-10 lg:space-y-0 lg:space-x-16 lg:items-center">
                      <div className="flex md:justify-center w-full lg:w-1/2">
                        <img
                          className="object-contain lg:max-h-[385px] w-full"
                          src={artist?.moreAbout?.section3?.img}
                          alt=""
                        />
                      </div>

                      <div className="w-full lg:w-1/2">
                        <div className="w-full space-y-6">
                          {artist?.moreAbout?.section3?.events
                            .sort(function (a, b) {
                              return b.year - a.year;
                            })
                            .map((event) => (
                              <div
                                key={event.text}
                                className="flex space-x-4 md:space-x-6"
                              >
                                <div className="font-bold text-[21px] leading-[21px] text-[#E9E9E9] w-14 flex-none">
                                  {event.year}
                                </div>

                                <p
                                  className="text-[#D3D3D3]"
                                  dangerouslySetInnerHTML={{
                                    __html: event.text,
                                  }}
                                ></p>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {artist?.moreAbout?.section4 && (
                  <div className="space-y-10 md:space-y-0 md:flex lg:space-y-0 lg:space-x-16 w-full pb-16 lg:pb-24">
                    <div className="w-full md:w-1/2 flex flex-col items-center">
                      <div className="w-full space-y-4">
                        <h3 className="text-[24px] font-bold text-[#E9E9E9]">
                          {artist?.moreAbout?.section4?.leftTitle}
                        </h3>

                        <div className="text-[#D3D3D3] space-y-4">
                          {artist?.moreAbout?.section4?.leftText.map(
                            (element) => (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: element,
                                }}
                                key={element}
                              ></p>
                            )
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="text-white space-y-4 w-full md:w-1/2">
                      <h3 className="text-[24px] font-bold text-[#E9E9E9]">
                        {artist?.moreAbout?.section4?.rightTitle}
                      </h3>

                      <div className="space-y-6">
                        {artist?.moreAbout?.section4?.rightText
                          .sort(function (a, b) {
                            return b.year - a.year;
                          })
                          .map((event) => (
                            <div
                              key={event.text}
                              className="flex space-x-4 lg:space-x-6"
                            >
                              <div className="font-bold text-[21px] leading-[21px] text-[#E9E9E9] w-14 flex-none">
                                {event.year}
                              </div>

                              <p
                                dangerouslySetInnerHTML={{
                                  __html: event.text,
                                }}
                                className="text-[#D3D3D3]"
                              ></p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="flex w-full justify-center pb-10">
                <Link
                  className="text-[#00263a] py-2 px-8 bg-white rounded-full text-[17px] font-source"
                  to={`/artist/${artist?.artistId}`}
                >
                  Show artworks
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtistDescription;
