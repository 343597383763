import React, { useRef, useState } from "react";
import { BsPlayCircleFill, BsFillPauseCircleFill } from "react-icons/bs";

const VideoPlayer = ({ path, poster, autoPlay }) => {
  const [startVideo, setStartVideo] = useState(autoPlay);
  const videoRef = useRef(null);

  const handlePlayPauseVideo = () => {
    if (startVideo) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }

    setStartVideo(!startVideo);
  };

  return (
    <div className="flex relative px-6 md:px-8 lg:px-0 w-full max-h-[200px] sm:max-h-[300px] md:max-h-[400px] lg:max-h-[545px]">
      <video
        autoPlay={autoPlay}
        muted={autoPlay}
        loop={autoPlay}
        ref={videoRef}
        src={path}
        poster={poster}
        className="flex w-full object-cover"
      />

      <div className="flex absolute left-0 right-0 top-0 bottom-0 items-center justify-center">
        {startVideo ? (
          <BsFillPauseCircleFill
            className="w-16 h-16 text-white cursor-pointer"
            onClick={handlePlayPauseVideo}
          />
        ) : (
          <BsPlayCircleFill
            className="w-16 h-16 text-white cursor-pointer"
            onClick={handlePlayPauseVideo}
          />
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
