import React, { useState, useEffect, useRef } from "react";
import Header from "../components/Header";
import StickBar from "../components/StickBar";
import { IoIosAlert } from "react-icons/io";
import { IoIosAddCircleOutline } from "react-icons/io";
import { SlPencil } from "react-icons/sl";
import ArtworksGallery from "../components/ArtworksGallery";
import useAuth from "../hooks/useAuth";
import { storage } from "../firebase";
import Modal from "@mui/material/Modal";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { RxCross2 } from "react-icons/rx";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TG_WALLET_ADDRESS, SERVER_URL } from "../globalVariables";

const Profile = () => {
  const {
    currentUser,
    moreInfoCurrentUser,
    error,
    UpdatePassword,
    UpdateEmail,
    UpdateUserData,
    openUpdateUserDataModal,
    setOpenUpdateUserDataModal,
    openReauthenticateModal,
    setOpenReauthenticateModal,
    reauthenticateUser,
    setError,
    setOpenConfirmEmailModal,
    ResendEmailVerification,
    deleteAccount,
  } = useAuth();
  const navigate = useNavigate();
  const [dataSelected, setDataSelected] = useState(false);
  const [name, setName] = useState({ firstName: "", lastName: "" });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [birthDate, setBirthDate] = useState({ month: "", day: "", year: "" });
  const hiddenFileInputProfilePicture = useRef(null);
  const hiddenFileInputCoverPicture = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [artworks, setArtworks] = useState(null);
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState(false);
  const [deleteAccountConfirm, setDeleteAccountConfirm] = useState(null);
  const [openErrorFileModal, setOpenErrorFileModal] = useState(false);
  const [userWalletAddress, setUserWalletAddress] = useState(null);
  const creationDateUser = new Date(currentUser?.metadata.creationTime);
  const creationDateUserMonth = creationDateUser.toLocaleString("default", {
    month: "long",
  });
  const creationDateUserYear = creationDateUser.getFullYear();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ValueReset = () => {
    setError("");
    setName({ firstName: "", lastName: "" });
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setViewPassword(false);
    setViewConfirmPassword(false);
    setBirthDate({ month: "", day: "", year: "" });
  };

  useEffect(() => {
    setIsLoading(true);

    const getArtworksByUser = async () => {
      try {
        let res;
        if (moreInfoCurrentUser?.provider === "moralis") {
          res = await fetch(
            `${SERVER_URL}/api/getArtworksByUser?owner=${moreInfoCurrentUser?.walletAddress}&userProvider=moralis`
          );
        }

        if (moreInfoCurrentUser?.provider === "firebase") {
          res = await fetch(
            `${SERVER_URL}/api/getArtworksByUser?owner=${moreInfoCurrentUser?.uid}&userProvider=firebase`
          );
        }

        res = await res.json();

        if (res) {
          setArtworks(res.result);
          setIsLoading(false);
        } else {
          navigate("/");
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (currentUser) {
      getArtworksByUser();
    }
  }, [currentUser]);

  const handleClickProfilePicture = () => {
    hiddenFileInputProfilePicture.current.click();
  };

  const handleClickCoverPicture = () => {
    hiddenFileInputCoverPicture.current.click();
  };

  const uploadProfilePictureToCloudStorage = (e) => {
    const image = e.target.files[0];

    const storageRef = ref(storage, `static-users/${image.name}`);

    const fileType = image.type;

    const allowedExtension = ["image/jpeg", "image/jpg", "image/png"];

    const fileSize = (image.size / 1024 / 1024).toFixed(2);

    if (image && fileSize < 2 && allowedExtension.indexOf(fileType) > -1) {
      uploadBytes(storageRef, image)
        .then(() => {
          getDownloadURL(storageRef)
            .then((url) => {
              UpdateUserData("profilePicture", url);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setOpenErrorFileModal(true);
    }
  };

  const uploadCoverPictureToCloudStorage = (e) => {
    const image = e.target.files[0];

    const storageRef = ref(storage, `static-users/${image.name}`);

    const fileType = image.type;

    const allowedExtension = ["image/jpeg", "image/jpg", "image/png"];

    const fileSize = (image.size / 1024 / 1024).toFixed(2);

    if (image && fileSize < 2 && allowedExtension.indexOf(fileType) > -1) {
      uploadBytes(storageRef, image)
        .then(() => {
          getDownloadURL(storageRef)
            .then((url) => {
              UpdateUserData("coverPicture", url);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setOpenErrorFileModal(true);
    }
  };

  const confirmButton = () => {
    if (dataSelected === "name") {
      var regName = /^[a-zA-Z-]*$/;

      if (name.firstName === "" || name.lastName === "") {
        setError("Please enter all fields.");
      } else if (
        regName.test(name.firstName) === false ||
        regName.test(name.lastName) === false
      ) {
        setError("Please enter a valid name");
      } else {
        UpdateUserData("name", name);
        ValueReset();
      }
    } else if (dataSelected === "email") {
      if (email === currentUser.email) {
        setError("This email already exists.");
      } else if (validator.isEmail(email) === false) {
        setError("Please enter a valid email.");
      } else {
        UpdateEmail(email);
        ValueReset();
      }
    } else if (dataSelected === "password") {
      if (password !== confirmPassword) {
        setError("Passwords do not match.");
      } else if (validator.isStrongPassword(password) === false) {
        setError(
          "Password must contain at least 8 characters with 1 number, 1 upper case letter and 1 special symbol."
        );
      } else {
        UpdatePassword(password);
        ValueReset();
      }
    } else if (dataSelected === "birthDate") {
      if (
        birthDate.day === "" ||
        birthDate.month === "" ||
        birthDate.year === ""
      ) {
        setError("please enter all fields.");
      } else if (birthDate.year !== "" && birthDate.year.length <= 3) {
        setError("Please enter a valid date (DD-MM-YYYY).");
      } else {
        UpdateUserData("birthDate", birthDate);
        ValueReset();
      }
    }
  };

  const handleDeleteAccount = () => {
    if (deleteAccountConfirm === "yes") {
      deleteAccount();
    } else {
      setOpenDeleteAccountModal(false);
    }
  };

  const handleDayChange = (e) => {
    const limit = 2;

    setBirthDate({
      ...birthDate,
      day:
        e.target.value.slice(0, limit) == 0
          ? ""
          : e.target.value.slice(0, limit) < 32 &&
            Math.sign(e.target.value) !== -1
          ? e.target.value.slice(0, limit)
          : e.target.value.slice(0, 1),
    });
  };

  const handleMonthChange = (e) => {
    const limit = 2;

    setBirthDate({
      ...birthDate,
      month:
        e.target.value.slice(0, limit) == 0
          ? ""
          : e.target.value.slice(0, limit) < 13 &&
            Math.sign(e.target.value) !== -1
          ? e.target.value.slice(0, limit)
          : e.target.value.slice(0, 1),
    });
  };

  const handleYearChange = (e) => {
    const limit = 4;
    let currentYear = new Date().getFullYear();

    setBirthDate({
      ...birthDate,
      year:
        e.target.value.length < 4 && Math.sign(e.target.value) !== -1
          ? e.target.value
          : e.target.value.slice(0, limit) >= currentYear - 100 &&
            e.target.value.slice(0, limit) <= currentYear - 16
          ? e.target.value.slice(0, limit)
          : "",
    });
  };

  const updateUser = (params) => {
    if (
      currentUser?.emailVerified ||
      moreInfoCurrentUser?.provider === "moralis"
    ) {
      setDataSelected(params);
      setOpenUpdateUserDataModal(true);
    } else {
      setOpenConfirmEmailModal(true);
      ResendEmailVerification();
    }
  };

  const transferArtworksToWallet = async () => {
    /*const tokens = artworks.filter(
      (artwork) => artwork.ownerOf === TG_WALLET_ADDRESS
    );

    if (tokens.length > 0) {
      try {
        const params = {
          newOwner: userWalletAddress,
          tokens: tokens,
        };

        const options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        };

        const res = await fetch(`${SERVER_URL}/api/withdrawArtwork`, options);

        if (res) {
          //afficher modal succes
        }

        console.log(res);
      } catch (error) {
        console.log(error);
        //afficher modal error
      }
    }*/
  };

  return (
    <div className="lg:max-w-[1440px] lg:mx-auto">
      <Header moreStyle={"sticky"} logo={true} />

      <div className="flex w-full">
        <StickBar moreStyle="z-20" />

        <div className="w-full space-y-14">
          <div className="relative h-[220px] lg:h-[300px] w-full">
            {moreInfoCurrentUser?.coverPicture ? (
              <img
                className="w-full h-full object-cover"
                src={moreInfoCurrentUser?.coverPicture}
              />
            ) : (
              <div className="w-full h-full bg-[#305592]" />
            )}

            <div
              className="absolute flex bottom-0 right-0 m-4 text-[#A8A8A8] cursor-pointer space-x-1 items-end"
              onClick={handleClickCoverPicture}
            >
              <SlPencil className="h-6 w-6" />

              <span className="font-source leading-[16px]"> Edit cover</span>
            </div>

            <input
              className="hidden"
              ref={hiddenFileInputCoverPicture}
              type="file"
              accept="image/*"
              onChange={uploadCoverPictureToCloudStorage}
            />
          </div>

          <div className="w-full lg:pl-[110px]">
            <div className="pl-6 md:pl-8 lg:pl-4 space-y-3">
              <div className="lg:flex lg:space-x-6 space-y-8 lg:space-y-0 pr-6 md:pr-8 lg:pr-4  w-full">
                <div className="relative flex-none w-28 h-28 md:w-32 md:h-32 lg:w-44 lg:h-44 -mt-[110px] lg:-mt-36">
                  {moreInfoCurrentUser?.profilePicture ? (
                    <img
                      className="w-full h-full rounded-full object-cover "
                      src={moreInfoCurrentUser?.profilePicture}
                    />
                  ) : (
                    <img
                      className="w-full h-full rounded-full object-cover "
                      src={"/images/tg-profile.png"}
                    />
                  )}

                  <IoIosAddCircleOutline
                    className="absolute bottom-0 right-2 h-6 w-6 md:h-7 md:w-7 lg:h-10 lg:w-10 text-white rounded-full"
                    onClick={handleClickProfilePicture}
                  />
                </div>

                <input
                  className="hidden"
                  ref={hiddenFileInputProfilePicture}
                  type="file"
                  accept="image/*"
                  onChange={uploadProfilePictureToCloudStorage}
                />

                <div className="space-y-6 md:space-y-0 md:flex md:space-x-10 justify-between w-full">
                  <div className="flex flex-col text-white space-y-2 lg:max-w-[500px]">
                    <div className="flex space-x-4 items-end">
                      <h2 className="leading-[35px] md:leading-[40px] capitalize text-[#D3D3D3]">
                        {moreInfoCurrentUser?.firstName &&
                        moreInfoCurrentUser?.lastName
                          ? moreInfoCurrentUser.firstName +
                            " " +
                            moreInfoCurrentUser.lastName
                          : "Firstname NAME"}
                      </h2>

                      <div
                        className="flex items-end space-x-1 text-[#A8A8A8] cursor-pointer"
                        onClick={() => updateUser("name")}
                      >
                        <SlPencil className="h-6 w-6" />

                        <span className="font-source leading-[16px]">Edit</span>
                      </div>
                    </div>

                    <p className="text-base lg:text-lg font-source text-[#D3D3D3]">
                      {currentUser
                        ? "Joined in " +
                          creationDateUserMonth +
                          " " +
                          creationDateUserYear
                        : ""}
                    </p>

                    {moreInfoCurrentUser?.provider === "firebase" &&
                      artworks?.length > 0 && (
                        <button
                          className="flex items-center font-source justify-center text-lg bg-white rounded-full w-48 h-10 text-black"
                          onAuxClick={transferArtworksToWallet}
                        >
                          Transfer to a wallet
                        </button>
                      )}
                  </div>

                  <div className="flex flex-col text-white space-y-4 lg:space-y-5">
                    <label className="text-[22px] lg:text-[28px]">
                      Your informations
                    </label>

                    <div className="flex space-x-4 items-center text-[#D3D3D3]">
                      <span className="text-lg leading-[18px]">
                        {moreInfoCurrentUser?.email
                          ? moreInfoCurrentUser.email
                          : "firstname.name@gmail.com"}
                      </span>

                      <div
                        className="flex items-end space-x-1 text-[#A8A8A8] cursor-pointer"
                        onClick={() => updateUser("email")}
                      >
                        <SlPencil className="h-6 w-6" />

                        <span className="font-source leading-[16px]">Edit</span>
                      </div>
                    </div>

                    {moreInfoCurrentUser?.provider === "firebase" && (
                      <div className="flex space-x-4 items-center text-[#D3D3D3]">
                        <span className="text-lg leading-[14px]">
                          ***********
                        </span>

                        <div
                          className="flex items-end space-x-1 cursor-pointer text-[#A8A8A8]"
                          onClick={() => updateUser("password")}
                        >
                          <SlPencil className="h-6 w-6" />

                          <span className="font-source leading-[16px]">
                            Edit
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="flex space-x-4 items-center text-[#D3D3D3]">
                      <span className="text-lg leading-[14px]">
                        {moreInfoCurrentUser?.birthDate.day &&
                        moreInfoCurrentUser?.birthDate.month &&
                        moreInfoCurrentUser?.birthDate.year
                          ? moreInfoCurrentUser.birthDate.day +
                            "." +
                            moreInfoCurrentUser.birthDate.month +
                            "." +
                            moreInfoCurrentUser.birthDate.year
                          : "JJ.MM.AAAA"}
                      </span>

                      <div
                        className="flex items-end space-x-1 cursor-pointer text-[#A8A8A8]"
                        onClick={() => updateUser("birthDate")}
                      >
                        <SlPencil className="h-6 w-6" />

                        <span className="font-source leading-[16px]">Edit</span>
                      </div>
                    </div>

                    <button
                      className="flex text-lg text-[#D3D3D3]"
                      onClick={() => setOpenDeleteAccountModal(true)}
                    >
                      Delete your account
                    </button>
                  </div>
                </div>
              </div>

              {isLoading ? (
                <div className="flex w-full justify-center items-center h-[400px]">
                  <CircularProgress
                    sx={{ color: "#33c7b6" }}
                    style={{ height: 60, width: 60 }}
                  />
                </div>
              ) : (
                <div className="space-y-7 lg:space-y-10">
                  <div className="space-y-1  pr-6 md:pr-8 lg:pr-4">
                    <div className="flex text-white space-x-10 lg:space-x-10 items-center">
                      <h3 className="font-medium">Your gallery</h3>

                      <h3 className="font-medium">
                        <span className="font-black">{artworks?.length}</span>

                        {artworks?.length > 1 ? " Artworks" : " Artwork"}
                      </h3>
                    </div>

                    <div className="bg-white rounded-full h-1 lg:h-2 w-full"></div>
                  </div>

                  <ArtworksGallery
                    itemsPerPage={6}
                    data={artworks}
                    pagination={true}
                    filterBar={false}
                    fullSizeOnClick={true}
                    price={false}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={openUpdateUserDataModal}
        onClose={() => {
          setOpenUpdateUserDataModal(false);
          ValueReset();
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white overflow-auto scrollbar-hide sm:rounded-xl w-full sm:w-[510px] h-full sm:h-[770px] outline-none relative">
          <div className="flex justify-end items-center p-6">
            <RxCross2
              className="w-11 h-11 cursor-pointer"
              onClick={() => {
                setOpenUpdateUserDataModal(false);
                ValueReset();
              }}
            />
          </div>

          <div className="flex flex-col space-y-16">
            <div className="flex flex-col items-center space-y-24">
              <img className="w-40" src="/images/logo-blue.png" alt="" />

              <p className="text-[#00263a] text-[24px] font-bold">
                {dataSelected === "name" && "Change your name"}
                {dataSelected === "email" && "Change your email adress"}
                {dataSelected === "password" && "Change your password"}
                {dataSelected === "birthDate" && "Change your birthdate"}
              </p>
            </div>

            <div className="px-6 sm:px-8 ">
              {dataSelected === "name" && (
                <div className="flex space-x-6">
                  <div className="font-source">
                    <label className="block">First name *</label>

                    <div className="flex mt-0.5 p-3 border border-gray-400 rounded-lg w-full">
                      <input
                        type="text"
                        className="w-full outline-none font-bold"
                        onChange={(event) => {
                          setName({
                            ...name,
                            firstName: event.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="font-source">
                    <label className="block">Last name *</label>

                    <div className="flex mt-0.5 p-3 border border-gray-400 rounded-lg w-full">
                      <input
                        type="text"
                        className="w-full outline-none font-bold"
                        onChange={(event) => {
                          setName({
                            ...name,
                            lastName: event.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {dataSelected === "email" && (
                <div className="flex flex-col font-source">
                  <label className="block ">Email adress *</label>

                  <div className="flex mt-0.5 p-3 border border-gray-400 rounded-lg w-full">
                    <input
                      type="email"
                      className="outline-none w-full font-bold"
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </div>
                </div>
              )}

              {dataSelected === "password" && (
                <div className="flex flex-col space-y-6">
                  <div className="font-source">
                    <label className="block ">New password *</label>

                    <div className="flex mt-0.5 p-3 border space-x-2 border-gray-400 rounded-lg w-full">
                      <input
                        type={viewPassword ? "text" : "password"}
                        className="outline-none w-full font-bold"
                        onChange={(event) => {
                          setPassword(event.target.value);
                        }}
                      />

                      {viewPassword ? (
                        <AiOutlineEyeInvisible
                          className="w-6 h-6 cursor-pointer"
                          onClick={() => setViewPassword(false)}
                        />
                      ) : (
                        <AiOutlineEye
                          className="w-6 h-6 cursor-pointer"
                          onClick={() => setViewPassword(true)}
                        />
                      )}
                    </div>
                  </div>

                  <div className="font-source">
                    <label className="block ">Confirm new password *</label>

                    <div className="flex mt-0.5 p-3 border space-x-2 border-gray-400 rounded-lg w-full">
                      <input
                        type={viewConfirmPassword ? "text" : "password"}
                        className="outline-none w-full font-bold"
                        onChange={(event) => {
                          setConfirmPassword(event.target.value);
                        }}
                      />

                      {viewConfirmPassword ? (
                        <AiOutlineEyeInvisible
                          className="w-6 h-6 cursor-pointer"
                          onClick={() => setViewConfirmPassword(false)}
                        />
                      ) : (
                        <AiOutlineEye
                          className="w-6 h-6 cursor-pointer"
                          onClick={() => setViewConfirmPassword(true)}
                        />
                      )}
                    </div>

                    <div className="pt-1">
                      <p className="text-xs">
                        Password must be at least 8 characters with 1 number, 1
                        upper case letter and 1 special symbol
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {dataSelected === "birthDate" && (
                <div className="flex space-x-6 font-source">
                  <div className="flex items-center mt-6">
                    <span className="block">Birthdate</span>
                  </div>

                  <div className="flex space-x-3 w-full">
                    <div className="max-w-[35%]">
                      <label className="block ">Day</label>

                      <div className="flex mt-0.5 p-4 border border-gray-400 rounded-lg w-full">
                        <input
                          type="number"
                          placeholder="DD"
                          className="w-full outline-none font-bold leading-none"
                          value={birthDate?.day}
                          onChange={handleDayChange}
                        />
                      </div>
                    </div>

                    <div className="max-w-[35%]">
                      <label className="block ">Month</label>

                      <div className="flex mt-0.5 p-4 border border-gray-400 rounded-lg w-full">
                        <input
                          type="number"
                          placeholder="MM"
                          className="w-full outline-none font-bold leading-none"
                          value={birthDate?.month}
                          onChange={handleMonthChange}
                        />
                      </div>
                    </div>

                    <div className="max-w-[55%]">
                      <label className="block ">Year</label>

                      <div className="flex mt-0.5 p-4 border border-gray-400 rounded-lg w-full ">
                        <input
                          type="number"
                          placeholder="YYYY"
                          className="w-full outline-none font-bold leading-none"
                          value={birthDate?.year}
                          onChange={handleYearChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {error && (
                <div className="">
                  <span className="block text-sm pt-2 font-medium text-[#FD866C]">
                    {error}
                  </span>
                </div>
              )}
            </div>

            <div className="flex justify-center pb-8">
              <button
                className="flex px-14 py-2 font-source bg-[#00263a] text-white rounded-full justify-center"
                onClick={() => confirmButton()}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openReauthenticateModal}
        onClose={() => {
          setOpenReauthenticateModal(false);
          ValueReset();
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl w-full overflow-auto scrollbar-hide sm:w-[520px] h-full sm:h-[770px] outline-none space-y-16">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenErrorModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-16 px-6">
            <div className="space-y-4 items-center text-center text-[#FD866C]">
              <p className="text-[24px] font-black">Your session has expired</p>

              <p className=" font-source">
                Please enter your email adress and your
                <br /> password to continue.
              </p>
            </div>

            <div className="flex flex-col">
              <div className="font-source">
                <label className="block ">Password *</label>

                <div className="flex mt-0.5 p-3 border space-x-2 border-gray-400 rounded-lg w-full">
                  <input
                    type={viewPassword ? "text" : "password"}
                    className="outline-none w-full font-bold"
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                  />

                  {viewPassword ? (
                    <AiOutlineEyeInvisible
                      className="w-6 h-6 cursor-pointer"
                      onClick={() => setViewPassword(false)}
                    />
                  ) : (
                    <AiOutlineEye
                      className="w-6 h-6 cursor-pointer"
                      onClick={() => setViewPassword(true)}
                    />
                  )}
                </div>
              </div>

              {error && (
                <div className="">
                  <span className="block text-sm pt-2 font-medium text-[#FD866C]">
                    {error}
                  </span>
                </div>
              )}
            </div>

            <div className="flex justify-center pb-8">
              <button
                className="flex px-14 py-2 bg-[#00263a] font-source text-white rounded-full justify-center"
                onClick={() => reauthenticateUser(password)}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openErrorFileModal}
        onClose={() => {
          setOpenErrorFileModal(false);
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl overflow-auto scrollbar-hide w-full sm:w-[520px] h-full sm:h-[770px] outline-none relative space-y-20 sm:space-y-36">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenErrorFileModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-6 items-center text-center pb-8">
            <IoIosAlert className="h-[50px] w-[50px] text-[#FD866C]" />

            <div className="space-y-12 items-center text-[#00263a] ">
              <p className="text-[24px] font-black">
                The file submitted is not <br /> supported by The Gallery
              </p>

              <p className="font-source">
                Please upload a JPEG, PNG or JPG file that <br />
                does not exceed 2 Mb.
              </p>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        open={openDeleteAccountModal}
        onClose={() => {
          setOpenDeleteAccountModal(false);
          ValueReset();
        }}
        className="flex items-center justify-center"
      >
        <div className="flex flex-col bg-white sm:rounded-xl overflow-auto scrollbar-hide w-full sm:w-[520px] h-full sm:h-[770px] outline-none space-y-16">
          <div className="">
            <div className="flex justify-end items-center p-6">
              <RxCross2
                className="w-11 h-11 cursor-pointer"
                onClick={() => {
                  setOpenDeleteAccountModal(false);
                }}
              />
            </div>

            <div className="flex justify-center">
              <img className="w-40" src="/images/logo-blue.png" alt="" />
            </div>
          </div>

          <div className="flex flex-col space-y-10 px-6 items-center pb-8">
            <p className="text-[24px] font-black text-center text-[#00263a]">
              Delete your account
            </p>

            <div className="flex flex-col space-y-6 items-center">
              <p className="font-source font-bold">
                Are you sure you want to delete your account ?
              </p>

              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={deleteAccountConfirm}
                onChange={(e) => setDeleteAccountConfirm(e.target.value)}
                className="space-y-2 text-[#00263a] font-black w-[52px]"
              >
                <FormControlLabel
                  value="no"
                  label="No"
                  control={
                    <Radio
                      sx={{
                        color: "#00263a",
                        "&.Mui-checked": {
                          "&, & + .MuiFormControlLabel-label": {
                            color: "#00263a",
                          },
                        },
                      }}
                    />
                  }
                  sx={{
                    color: "#00263a",
                    ".MuiFormControlLabel-label": {
                      fontWeight: "600",
                    },
                  }}
                  className="space-x-2"
                />

                <FormControlLabel
                  value="yes"
                  label="Yes"
                  control={
                    <Radio
                      sx={{
                        color: "#00263a",
                        "&.Mui-checked": {
                          "&, & + .MuiFormControlLabel-label": {
                            color: "#00263a",
                          },
                        },
                      }}
                    />
                  }
                  className="space-x-2"
                />
              </RadioGroup>
            </div>

            <button
              className="flex px-14 py-2 bg-[#00263a] font-source text-white rounded-full justify-center"
              onClick={handleDeleteAccount}
            >
              Confirm
            </button>

            <p className="font-source text-[#00263a] text-center max-w-[280px]">
              If you delete your account, your data is deleted but not your NFT.{" "}
              <br />
              You can find your NFT in your wallet.
            </p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;
