import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import StickBar from "../components/StickBar";
import { TfiWorld, TfiFacebook, TfiLinkedin } from "react-icons/tfi";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";
import {
  collection,
  getDocs,
  query,
  where,
  getCountFromServer,
  collectionGroup,
} from "firebase/firestore";
import { db } from "../firebase";
import { useParams, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import ArtworksGallery from "../components/ArtworksGallery";
import CollectionsSection from "../sections/ArtistPage/CollectionsSection";
import { SERVER_URL } from "../globalVariables";
import useWindowsResize from "../hooks/useWindowsResize";
import Gallery3D from "../components/Gallery3D";
import CollectionCotentin from "../sections/ArtistPage/CollectionCotentin";

const Artist = () => {
  let navigate = useNavigate();
  const { artistId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [artistData, setArtistData] = useState(null);
  const [selectSection, setSelectSection] = useState("Collection");
  const [artworks, setArtworks] = useState([]);
  const [collections, setCollections] = useState(null);
  const screenSize = useWindowsResize();

  useEffect(() => {
    resetValue();
    window.scrollTo(0, 0);
  }, [artistId]);

  useEffect(() => {
    const getArtist = async () => {
      const docRef = collection(db, "artists");
      const q = query(docRef, where("artistId", "==", artistId));
      const querySnapshot = await getDocs(q);

      let artist = null;
      querySnapshot.forEach((doc) => {
        artist = doc.data();
      });

      if (artist) {
        setArtistData(artist);
      } else {
        navigate("/");
      }
    };

    getArtist();
  }, [artistId]);

  useEffect(() => {
    const getAtworks = async () => {
      try {
        let res = await fetch(
          `${SERVER_URL}/api/getArtworksByArtist?artistId=${artistId}`
        );
        res = await res.json();

        if (res) {
          setArtworks(res);
          await getCollections();
        }
      } catch (error) {
        navigate("/");
      }
    };

    const getCollections = async () => {
      let collections = [];

      const docRef = collectionGroup(db, "collections");
      const q = query(docRef, where("artistId", "==", artistId));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        collections.push(doc.data());
      });

      for (let i = 0; i < collections?.length; i++) {
        collections[i].nbOfArtworks = await getNbOfArtworks(
          collections[i].name
        );
      }

      setCollections(collections);
      setIsLoading(false);
    };

    const getNbOfArtworks = async (collectionName) => {
      const coll = collection(db, "artworks");

      const q = query(
        coll,
        where("collectionName", "==", collectionName),
        where("artworkId", "!=", 0)
      );

      const snapshot = await getCountFromServer(q);

      return snapshot.data().count;
    };

    if (artistData) {
      getAtworks();
    }
  }, [artistData, artistId]);

  const resetValue = () => {
    setIsLoading(true);
    setArtworks([]);
    setArtistData(null);
    setCollections(null);
  };

  return (
    <>
      <div className="flex flex-col lg:max-w-[1440px] lg:mx-auto">
        <Header moreStyle="sticky" logo={true} />

        <div className="flex ">
          <StickBar />

          {isLoading && artworks ? (
            <div className="flex w-full h-[calc(100vh-100px)] justify-center items-center">
              <CircularProgress
                sx={{ color: "#33c7b6" }}
                style={{ height: 80, width: 80 }}
              />
            </div>
          ) : (
            <div className="w-full lg:pl-[110px] pt-8 md:pt-10">
              <div className="w-full pl-6 md:pl-8 lg:pl-4 space-y-8 p md:space-y-10 ">
                <div className="lg:flex space-y-7 md:space-y-10 lg:space-y-0 lg:space-x-7 pr-6 md:pr-8">
                  <div className="lg:max-w-[320px] text-white space-y-3 lg:space-y-4 flex-none">
                    <img
                      src={artistData?.profilePicture}
                      className="w-[50px] h-[50px] md:w-[70px] md:h-[70px] lg:w-[90px] lg:h-[90px] object-cover object-right-top rounded-full "
                      alt=""
                    />

                    <h1 className="leading-[60px]">
                      {artistData?.firstName + " " + artistData?.lastName}
                    </h1>

                    <div className="flex space-x-3 lg:space-x-4">
                      {artistData?.links.website && (
                        <a
                          className=""
                          href={artistData?.links.website}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <TfiWorld className="w-5 h-5 lg:w-6 lg:h-6" />
                        </a>
                      )}

                      {artistData?.links.instagram && (
                        <a
                          className=""
                          href={artistData?.links.instagram}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <BsInstagram className="w-5 h-5 lg:w-6 lg:h-6" />
                        </a>
                      )}

                      {artistData?.links.twitter && (
                        <a
                          className=""
                          href={artistData?.links.twitter}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <BsTwitter className="w-5 h-5 lg:w-6 lg:h-6" />
                        </a>
                      )}

                      {artistData?.links.linkedin && (
                        <a
                          className=""
                          href={artistData?.links.linkedin}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <TfiLinkedin className="w-5 h-5 lg:w-6 lg:h-6" />
                        </a>
                      )}

                      {artistData?.links.facebook && (
                        <a
                          className=""
                          href={artistData?.links.facebook}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <TfiFacebook className="w-5 h-5 lg:w-6 lg:h-6 -ml-2" />
                        </a>
                      )}

                      {artistData?.links.tiktok && (
                        <a
                          className=""
                          href={artistData?.links.tiktok}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaTiktok className="w-5 h-5 lg:w-6 lg:h-6 -ml-2" />
                        </a>
                      )}
                    </div>

                    <div className="space-y-1.5">
                      <p className="text-[20px] font-medium">
                        {artistData?.statusPro}
                      </p>

                      <p className="text-[16px] font-medium italic">
                        {artistData?.slogan}
                      </p>

                      <Link
                        className="text-[16px] underline underline-offset-4"
                        to={`/artist/${artistId}/description`}
                      >
                        Read more
                      </Link>
                    </div>
                  </div>

                  <div className=" w-full">
                    <img
                      className="w-full max-h-[350px] md:max-h-[450px] lg:min-h-[350px] lg:max-h-[550px] object-cover"
                      src={artistData?.coverPicture}
                      alt=""
                    />
                  </div>
                </div>

                <div className="space-y-7 lg:space-y-10">
                  <div className="flex overflow-x-auto scrollbar-hide w-full  text-white pr-6 md:pr-8">
                    <button
                      className="flex flex-col space-y-2 flex-none"
                      onClick={() => setSelectSection("Collection")}
                    >
                      <h3 className="pr-5 font-medium">
                        <span className="font-black">
                          {collections?.length}
                        </span>

                        {collections?.length == 1
                          ? " Collection"
                          : " Collections"}
                      </h3>

                      <span
                        className={
                          "h-1 lg:h-2 w-full rounded-l-full " +
                          (selectSection === "Collection"
                            ? "bg-[#33c7b6]"
                            : "bg-white")
                        }
                      ></span>
                    </button>

                    {artistId !== "D-Day-en-Cotentin" && (
                      <button
                        className="flex flex-col items-center space-y-2 flex-none"
                        onClick={() => setSelectSection("Artworks")}
                      >
                        <h3 className="px-5 pr-5 font-medium">
                          <span className="font-black">{artworks?.length}</span>

                          {artworks?.length == 1 ? " Artwork" : " Artworks"}
                        </h3>

                        <span
                          className={
                            "h-1 lg:h-2 w-full " +
                            (selectSection === "Artworks"
                              ? "bg-[#33c7b6]"
                              : "bg-white")
                          }
                        ></span>
                      </button>
                    )}

                    <button
                      className="flex flex-col items-center space-y-2 flex-none"
                      onClick={() => setSelectSection("Gallery3d")}
                    >
                      <div className=" bg-[#33c7b6] rounded-full">
                        <h3 className="px-5 pr-5 font-medium">
                          View in the 3D Gallery
                        </h3>
                      </div>

                      <span
                        className={
                          "h-1 lg:h-2 w-full " +
                          (selectSection === "Gallery3d"
                            ? "bg-[#33c7b6]"
                            : "bg-white")
                        }
                      ></span>
                    </button>

                    <div className="flex grow items-end">
                      <span
                        className={
                          "h-1 lg:h-2 w-full rounded-r-full " +
                          (selectSection === "Collection"
                            ? "bg-[#33c7b6] sm:bg-white"
                            : "bg-white")
                        }
                      ></span>
                    </div>
                  </div>

                  {selectSection === "Artworks" ? (
                    <ArtworksGallery
                      itemsPerPage={screenSize > 768 ? 12 : 7}
                      data={artworks}
                      pagination={true}
                      filterBar={true}
                    />
                  ) : selectSection === "Collection" ? (
                    artistId === "D-Day-en-Cotentin" ? (
                      <CollectionCotentin data={collections} />
                    ) : (
                      <CollectionsSection data={collections} />
                    )
                  ) : (
                    <div className="w-full pr-6 md:pr-8 pb-10 h-[300px] md:h-[400px] lg:h-[700px]">
                      <Gallery3D
                        artistId={artistId}
                        exhibitionId={artistData?.exhibitionId}
                        fullSize={false}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Artist;
