import React from "react";
import { RxCaretRight, RxCaretLeft } from "react-icons/rx";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CarouselSmall = ({ children }) => {
  function CustomLeftArrow({ onClick }) {
    return (
      <RxCaretLeft
        onClick={onClick}
        className="cursor-pointer left-0 lg:-left-12 top-28 absolute w-14 h-14 text-white z-10 "
      ></RxCaretLeft>
    );
  }

  function CustomRightArrow({ onClick }) {
    return (
      <RxCaretRight
        onClick={onClick}
        className="cursor-pointer right-6 lg:-right-5 top-28 absolute w-14 h-14 z-10 text-white"
      ></RxCaretRight>
    );
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
      slidesToSlide: 1,
    },
    mediumDesktop: {
      breakpoint: { max: 1200, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  return (
    <div className="relative">
      <Carousel
        draggable={false}
        containerClass="!static"
        itemClass="!static pr-6 md:pr-8"
        infinite
        showDots={false}
        responsive={responsive}
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default CarouselSmall;
