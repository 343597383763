import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CircleType from "circletype";

export default function Close(props) {
  const navigate = useNavigate();
  const [state, setState] = useState(false);

  const circle = useRef();
  useEffect(() => {
    new CircleType(circle.current).radius();
  }, []);

  const change = () => {
    setState(true);
  };

  const changeOff = () => {
    setState(false);
  };

  const goHome = async () => {
    navigate(-1);
  };

  return (
    <div
      onClick={goHome}
      onMouseOver={change}
      onMouseOut={changeOff}
      id="close"
    >
      <img
        className={state === true ? "img-bigger" : ""}
        src={
          props.elem === undefined || props.elem === "black"
            ? "/images/x.png"
            : "/images/x-white.png"
        }
        alt="close"
        id="close-x"
      ></img>
      <p
        style={
          props.elem === "black" || props.elem === undefined
            ? ""
            : { color: "white" }
        }
        ref={circle}
        id="close-text"
      >
        Cancel&emsp; Cancel &emsp;Cancel
      </p>
    </div>
  );
}
